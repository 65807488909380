import React, { useState, useEffect, useCallback } from 'react';
import PT from 'prop-types';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { getRoutes, qaAttr } from 'utils';
import { Box, styled } from 'components';
import { PopupMenu, Spinner } from 'components/shared';
import LayoutViewController, {
  GRID_LAYOUT,
  LIST_LAYOUT
} from 'components/shared/LayoutViewController';
import { MdArrowDropDown } from 'components/icons';
import {
  GrowLink,
  MyJobsSearch,
  MyJobsCardItem,
  MyJobsRowItem
} from 'components/Dashboard/employee';
import { withEmployeeJobActions } from 'hocs';
import { useMediaQueryMatches } from 'hooks';
import { myJobsStyles } from 'styles/Dashboard/EmployeeDashboard';
import { GET_MY_JOBS } from 'api';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    event: 'pageViewAllJobs',
    pagePath: '/myjobs',
    pageTitle: 'My Jobs',
  },
}

//trigger Google analytics event
TagManager.dataLayer(tagManagerArgs);

const FILTERS = {
  all: 'All Jobs',
  favorites: 'Favorites'
};

const StyledRoot = styled('div')(myJobsStyles);

const enhance = (WrappedComponent) => withEmployeeJobActions(WrappedComponent);

function MyJobs(props) {
  const { onStar, onRetract } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const client = useApolloClient();

  const [fetchMyJobs, { data: jobsData, loading: jobsLoading = true }] = useLazyQuery(GET_MY_JOBS, {
    variables: { query: '' },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  });
  const jobs = jobsData?.myJobs || [];

  const [layoutMode, setLayoutMode] = useState(LIST_LAYOUT);
  const [searchValue, setSearchValue] = useState('');
  const [filterBy, setFilterBy] = useState(FILTERS.all);
  const [isSearchResultSelected, setIsSearchResultSelected] = useState(false);

  const resetAll = useCallback(() => {
    setIsSearchResultSelected(false);
    // clear employeeJobs from cache
    client.cache.evict({ fieldName: 'myJobs', broadcast: true });
    client.cache.gc();
    fetchMyJobs({
      variables: { query: JSON.stringify({ search: '', selectedValue: filterBy }) }
    });
  }, [client.cache, fetchMyJobs, filterBy]);

  useEffect(() => {
    fetchMyJobs({
      variables: { query: JSON.stringify({ search: searchValue, selectedValue: filterBy }) }
    });
  }, [filterBy]);

  const starJob = useCallback(
    (job, starred, userToJob) =>
      onStar(job, starred, {
        onSuccess: () => {
          // update starred value in cached myJobs
          // broadcast is disabled to prevent query refetch
          client.cache.updateQuery({ query: GET_MY_JOBS, broadcast: false }, (d) => ({
            myJobs: d.myJobs.map((o) => (o.id === userToJob.id ? { ...d, starred } : d))
          }));
        }
      }),
    [client.cache, onStar]
  );

  const retractJob = useCallback(
    (job, userToJob) => {
      onRetract(job, {
        onSuccess: () => {
          // remove ref to cached object from myJobs
          client.cache.modify({
            broadcast: true,
            fields: {
              myJobs(refs, { readField }) {
                return refs.filter((ref) => userToJob.id !== readField('id', ref));
              }
            }
          });
          const cachedId = client.cache.identify(userToJob);
          if (cachedId) {
            // remove cached object
            const removed = client.cache.evict({ id: cachedId, broadcast: false });
            if (removed) client.cache.gc();
          }
        }
      });
    },
    [client.cache, onRetract]
  );

  const changeSearchValue = useCallback((e, { newValue }) => setSearchValue(newValue), []);

  const onSearchClear = useCallback(() => {
    setSearchValue('');
    resetAll();
  }, [resetAll]);

  const onSuggestionSelect = useCallback(
    (e, { suggestionValue, suggestion, inputRef }) => {
      // set selected job to cache instead of current
      client.cache.updateQuery({ query: GET_MY_JOBS, broadcast: false, overwrite: true }, (d) => ({
        myJobs: [{ ...suggestion }]
      }));
      setIsSearchResultSelected(true);
    },
    [client.cache]
  );

  const handleFilterChange = (e, i, item, { setAnchorEl, closePopup }) => {
    closePopup();
    setFilterBy(item.value);
  };

  const renderSearch = () => (
    <div className="autocompleteContainer">
      <MyJobsSearch
        value={searchValue}
        filterValue={filterBy}
        onChange={changeSearchValue}
        onClear={onSearchClear}
        onSelect={onSuggestionSelect}
      />
      <div className="filtersContainer">
        <PopupMenu
          id="search-filter"
          disablePortal
          triggerTitle={filterBy}
          triggerProps={{
            endIcon: <MdArrowDropDown color="inherit" />,
            className: 'filterPopupTrigger',
            testID: 'my-jobs-filter-button'
          }}
          items={[
            {
              value: FILTERS.all,
              label: FILTERS.all,
              itemProps: {
                className: 'optionsPopupBtn',
                ...qaAttr('all-jobs-filter-option-button')
              }
            },
            {
              value: FILTERS.favorites,
              label: FILTERS.favorites,
              itemProps: {
                className: 'optionsPopupBtn',
                ...qaAttr('favorites-jobs-filter-option-button')
              }
            }
          ]}
          onItemClick={handleFilterChange}
        />
        {isDesktop && (
          <LayoutViewController
            value={layoutMode}
            className="layoutController"
            onChange={setLayoutMode}
          />
        )}
      </div>
    </div>
  );

  const renderRowItems = () =>
    jobs.map((userToJob) => {
      const { id, approved, starred, jobs: job, updatedAt, userInterviewDate } = userToJob;
      // @FIXME: myJobs include userInterviewDate only in UserToJobs but not in Job (ask for fix on backend)
      const jobWithInterview = { ...job, userInterviewDate };
      return (
        <MyJobsRowItem
          key={`job__${job.id}`}
          className="listItemContainer"
          job={jobWithInterview}
          applyDate={updatedAt}
          isApproved={approved}
          isStarred={starred}
          onStar={(jobData, starredVal) => starJob(jobData, starredVal, userToJob)}
          onRetract={(jobData) => retractJob(jobData, userToJob)}
        />
      );
    });

  const renderJobs = () => {
    if (isDesktop) {
      if (layoutMode === GRID_LAYOUT) {
        return (
          <div className="jobsContainer gridView">
            {jobs.map((userToJob) => {
              const { id, approved, starred, jobs: job, updatedAt, userInterviewDate } = userToJob;
              const jobWithInterview = { ...job, userInterviewDate };
              return (
                <MyJobsCardItem
                  key={`job__${job.id}`}
                  job={jobWithInterview}
                  applyDate={updatedAt}
                  isApproved={approved}
                  isStarred={starred}
                  onStar={(jobData, starredVal) => starJob(jobData, starredVal, userToJob)}
                  onRetract={(jobData) => retractJob(jobData, userToJob)}
                />
              );
            })}
          </div>
        );
      }
      return <div className="jobsContainer">{renderRowItems()}</div>;
    }

    return <div className="jobsContainer">{renderRowItems()}</div>;
  };

  return (
    <StyledRoot className="container">
      {!isDesktop && (
        <Box mb="16px" px="16px">
          <GrowLink />
        </Box>
      )}
      {renderSearch()}
      <div className="contentWrapper">
        {renderJobs()}
        {jobsLoading && (
          <div className="contentLoader">
            <Spinner size={30} />
          </div>
        )}
      </div>
    </StyledRoot>
  );
}

MyJobs.propTypes = {
  onStar: PT.func.isRequired,
  onRetract: PT.func.isRequired
};

export default enhance(MyJobs);
