import React, { useState, useEffect, useCallback } from 'react';
import PT from 'prop-types';
import format from 'date-fns/format';
import { getProfileId, getLocaleTimeZone, qaAttr } from 'utils';
import { useEmployeeProfileQuery } from 'hooks';
import { Box, Dialog, styled } from 'components';
import styles from 'styles/Dashboard/EmployeeJobCard';
import Button, { IconButton } from '../../shared/Button';
import Spinner from '../../shared/Spinner';
import { MdArrowBack } from '../../icons';
import useJobSchedule from '../useJobSchedule';
import JobInterviewSchedule from '../JobInterviewSchedule/JobInterviewSchedule';
import { track } from 'utils/segmentAnalytics';
import { trackPixelEvent } from 'utils/fbpixel';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    event: 'Apply to Job'
  },
}

const StyledDialog = styled(Dialog)(styles);

function EmployeeInterviewScheduleModal(props) {
  const {
    DialogProps,
    employerProfileName,
    employerProfileId,
    interviewSaveLoading,
    isOpen,
    jobId,
    jobTitle,
    onClose,
    onConfirm
  } = props;
  const { profile } = useEmployeeProfileQuery();
  const { timeZone = getLocaleTimeZone() } = profile || {};
  const {
    employerTimeZone,
    getJobSchedule,
    getJobInterviews,
    inactiveDates,
    interviewDate,
    schedules,
    setInterviewDate,
    setInactiveDates
  } = useJobSchedule();

  const [isInterviewConfirmation, setIsInterviewConfirmation] = useState(false);
  const [chooseAnotherDate, setChooseAnotherDate] = useState(false);
  const [employerScheduleId, setEmployerScheduleId] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      const profileId = getProfileId();
      getJobSchedule(employerProfileId);
      getJobInterviews(jobId, profileId, timeZone);
    }
  }, [employerProfileId, getJobInterviews, getJobSchedule, isOpen, jobId, timeZone]);

  const handleInterviewDateSelect = useCallback(
    (date, scheduleId) => {
      setError('');
      setInterviewDate(date);
      setEmployerScheduleId(scheduleId);
      setIsInterviewConfirmation(!!date);
      setChooseAnotherDate(false);
    },
    [setInterviewDate]
  );

  const confirmNewInterviewDate = async (date) => {
    const profileId = Number(getProfileId());
    const data = {
      employeeProfileId: profileId,
      jobId,
      date,
      employerScheduleId
    };

    if (profileId && jobId && date && employerScheduleId) {
      await onConfirm(data, {
        onSuccess: ({ bookedDate, countLimit }) => {
          setInterviewDate(null);
          setEmployerScheduleId(null);
          setIsInterviewConfirmation(false);
          track('Scheduled Interview');
          //trigger FBpixel event
          trackPixelEvent('Apply to Job');
          //trigger Google analytics event
          TagManager.dataLayer(tagManagerArgs);

          if (bookedDate || countLimit) {
            // in that case date has been already taken
            // make that date disabled
            setInactiveDates((prev) => [...prev, date]);
            setChooseAnotherDate(true);
          }
        },
        onError: () => {}
      });
    } else {
      let reason = '';
      if (!profileId || !jobId) reason = 'Invalid arguments.';
      if (!date) reason = 'Invalid date.';
      if (!employerScheduleId) reason = 'Invalid schedule id.';
      setError(`Something went wrong! ${reason}`);
    }
  };

  return (
    <StyledDialog
      open={isOpen}
      fullScreen
      onClose={onClose}
      classes={{ paperWidthSm: 'scheduleModal' }}
      {...DialogProps}
    >
      <div className="mobileScheduleModal__header">
        <IconButton
          color="primary"
          sx={{ width: 0, height: 0 }}
          onClick={onClose}
          testID="schedule-modal-close-button"
        >
          <MdArrowBack />
        </IconButton>
        <div style={{ width: '100%', paddingLeft: 6, paddingRight: 6 }}>
          <h2 className="mobileScheduleModal__title">Select Interview Time</h2>
          <div className="mobileScheduleModal__subTitle">{`${jobTitle} @ ${employerProfileName}`}</div>
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <JobInterviewSchedule
          employerProfileId={employerProfileId}
          employerTimeZone={employerTimeZone}
          inactiveDates={inactiveDates}
          interviewDate={interviewDate}
          onDateSelect={handleInterviewDateSelect}
          schedules={schedules}
          testID={jobTitle}
          userTimeZone={timeZone}
          withCancel={false}
          withConfirmation={false}
        />
      </div>
      {chooseAnotherDate && (
        <Box mb="40px" display="flex" alignItems="center" flexDirection="column">
          <div className="mobileScheduleModal__confirmTitle">This time is no longer available</div>
          <Button
            variant="filled-primary"
            sx={{ height: 46 }}
            onClick={() => setChooseAnotherDate(false)}
            testID="schedule-modal-submit-button"
          >
            Choose New Time
          </Button>
        </Box>
      )}
      {isInterviewConfirmation && interviewDate && (
        <Box mb="40px" display="flex" alignItems="center" flexDirection="column">
          <div className="mobileScheduleModal__confirmTitle">Confirm interview on</div>
          <div className="mobileScheduleModal__confirmDate">
            {format(interviewDate, "EEEE, MMM do 'at' hh:mm a?")}
          </div>
          <Button
            variant="filled-primary"
            disabled={interviewSaveLoading}
            endIcon={interviewSaveLoading ? <Spinner size={24} /> : null}
            sx={{ width: 162, height: 46 }}
            onClick={() => confirmNewInterviewDate(interviewDate, employerScheduleId)}
            testID="schedule-modal-submit-button"
          >
            Go get it!
          </Button>
          {error && (
            <Box my="5px" color="error.main" {...qaAttr('schedule-modal-error-message')}>
              {error}
            </Box>
          )}
        </Box>
      )}
    </StyledDialog>
  );
}

EmployeeInterviewScheduleModal.propTypes = {
  employerProfileName: PT.string.isRequired,
  employerProfileId: PT.number.isRequired,
  interviewSaveLoading: PT.bool,
  isOpen: PT.bool.isRequired,
  jobId: PT.number.isRequired,
  jobTitle: PT.string.isRequired,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired
};

EmployeeInterviewScheduleModal.defaultProps = {
  interviewSaveLoading: false
};

export default EmployeeInterviewScheduleModal;
