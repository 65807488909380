import React, { useState, useCallback, useMemo, useRef, memo } from 'react';
import PT from 'prop-types';
import { Deck, IconButton, Spinner } from 'components/shared';
import { MdClose, MdArrowBack, MdCheck, MdStarBorder } from 'components/icons';
import { EmployeeJobCard } from 'components/Job';
import { EmployeeInterviewScheduleModal } from 'components/Interview';


function JobsSwiper(props) {
  const {
    applyLoading,
    initialIndex,
    onApply,
    onReject,
    onRetract,
    onStar,
    interviewSaveLoading,
    jobs,
    jobsLoading,
    onJobSelect,
    onJobsRefresh,
    onLoadMore,
    onSaveInterview,
    rejectLoading,
    retractLoading,
    starLoading
  } = props;
  const deckRef = useRef();

  const [cardIndex, setCardIndex] = useState(initialIndex);
  const [scheduleModalConfig, setScheduleModalConfig] = useState({ isOpen: false });
  const currentJob = jobs?.[cardIndex] || {};
  const isStarred = currentJob.starred;

  const actionsDisabled =
    cardIndex > jobs.length - 1 ||
    jobsLoading ||
    rejectLoading ||
    applyLoading ||
    starLoading ||
    rejectLoading;
  const isRetractDisabled = cardIndex === 0;

  const openScheduleModal = useCallback((job) => {
    setScheduleModalConfig({
      isOpen: true,
      jobId: job?.id,
      jobTitle: job?.title,
      employerProfileName: job?.employerProfile?.name,
      employerProfileId: job?.employerProfile?.profile_id
    });
  }, []);

  const closeInterviewModal = useCallback(() => {
    setScheduleModalConfig({ isOpen: false });
    // return card
    deckRef.current.swipeBack();
    setCardIndex((idx) => idx - 1);
  }, []);

  const handleInterviewConfirm = useCallback(
    ({ employeeProfileId, date, employerScheduleId, jobId }, options) => {
      const { onSuccess } = options || {};

      onSaveInterview(
        {
          date,
          employeeProfileId,
          employerScheduleId,
          jobId
        },
        {
          onSuccess: (result) => {
            const { bookedDate, countLimit } = result;
            onSuccess(result);

            // bookedDate or countLimit will be true only if date has been already taken
            if (!bookedDate && !countLimit) {
              setScheduleModalConfig({ isOpen: false });
            }
          }
        }
      );
    },
    [onSaveInterview]
  );

  const onSwipeLeft = useCallback(
    (item, index, params) => {
      if (item?.id) {
        const nextIndex = index + 1;
        setCardIndex(nextIndex);
        onLoadMore({ index });
        onReject(item, {
          onSuccess: () => {
            // refresh all jobs if last one was processed
            if (nextIndex > jobs.length - 1) {
              setCardIndex(0);
              onJobsRefresh();
            }
          },
          onCancel: () => {
            // return card
            deckRef.current.swipeBack();
            setCardIndex((idx) => idx - 1);
          }
        });
      }
    },
    [onLoadMore, onReject, jobs.length, onJobsRefresh]
  );

  const onSwipeRight = useCallback(
    (item, index, params) => {
      if (item?.id) {
        const nextIndex = index + 1;
        setCardIndex(nextIndex);
        onLoadMore({ index });
        onApply(item, {
          onSuccess: () => {
            // refresh all jobs if last one was processed
            if (nextIndex > jobs.length - 1) {
              setCardIndex(0);
              onJobsRefresh();
            }
          },
          onCancel: () => {
            // return card
            deckRef.current.swipeBack();
            setCardIndex((idx) => idx - 1);
          },
          showSchedule: () => openScheduleModal(item)
        });
      }
    },
    [jobs.length, onApply, onJobsRefresh, onLoadMore, openScheduleModal]
  );

  const handleSwipeLeft = (params = {}) => {
    const { item, index } = deckRef.current.swipeLeft();
    onSwipeLeft(item, index, params);
  };

  const handleSwipeRight = useCallback(
    (params = {}) => {
      const { item, index } = deckRef.current.swipeRight();
      onSwipeRight(item, index, params);
    },
    [onSwipeRight]
  );

  const handleRetract = () => {
    const currentIdx = cardIndex;
    const prevJob = jobs[currentIdx - 1];
    deckRef.current.swipeBack();
    setCardIndex((idx) => idx - 1);

    onRetract(prevJob, {
      onCancel: () => {
        deckRef.current?.jumpToCardIndex(currentIdx + 1);
      }
    });
  };

  const deckLeftLabel = useMemo(() => <div className="deckLabel__pass">Pass</div>, []);
  const deckRightLabel = useMemo(() => <div className="deckLabel__apply">Apply</div>, []);

  const renderDeckItem = useCallback(
    (job, { disabled }) => (
      <EmployeeJobCard
        job={job}
        onSelect={onJobSelect}
        onScheduleCall={() => handleSwipeRight()}
        routerLinkProps={{ state: { isAllJobsOpener: true } }}
        withSchedule
      />
    ),
    [handleSwipeRight, onJobSelect]
  );

  return (
    <>
      <Deck
        classNames={{
          card: 'mobileJobCard',
          cardChild: 'mobileJobCardContent',
          cardWrapper: 'mobileJobCardWrapper',
          deckWrapper: 'jobsDeckWrapper'
        }}
        disabled={actionsDisabled}
        initIndex={cardIndex}
        items={jobs}
        leftLabel={deckLeftLabel}
        onSwipeLeft={onSwipeLeft}
        onSwipeRight={onSwipeRight}
        ref={deckRef}
        renderItem={renderDeckItem}
        rightLabel={deckRightLabel}
        // onDeckHeightChange={handleDeckHeightChange}
      />
      {jobs?.length > 0 && (
        <div className="mobileControllers">
          <div className="mobileControllerWrapper">
            <div className="mobileControllerBtnWrapper">
              <IconButton
                variant="outlined"
                color="primary"
                aria-describedby="mobile-cancel-button"
                disabled={actionsDisabled || isRetractDisabled}
                sx={{ width: 42, height: 42 }}
                onClick={handleRetract}
                testID="job-retract-button"
              >
                {retractLoading ? <Spinner size={24} /> : <MdArrowBack />}
              </IconButton>
            </div>
            <div className="mobileControllerLabel" id="mobile-cancel-button">
              Cancel
            </div>
          </div>
          <div className="mobileControllerWrapper">
            <div className="mobileControllerBtnWrapper">
              <IconButton
                variant="outlined"
                color="primary"
                aria-describedby="mobile-skip-button"
                disabled={actionsDisabled}
                classes={{ root: `deckAction big` }}
                sx={{ fontSize: 32 }}
                onClick={() => handleSwipeLeft()}
                testID="job-reject-button"
              >
                {rejectLoading ? <Spinner size={30} /> : <MdClose fontSize="inherit" />}
              </IconButton>
            </div>
            <div className="mobileControllerLabel" id="mobile-skip-button">
              Skip Job
            </div>
          </div>
          {!currentJob?.isSchedule && (
            <div className="mobileControllerWrapper">
              <div className="mobileControllerBtnWrapper">
                <IconButton
                  variant="outlined"
                  color="primary"
                  aria-describedby="mobile-apply-button"
                  disabled={actionsDisabled}
                  classes={{ root: `deckAction big` }}
                  sx={{ fontSize: 32 }}
                  onClick={() => handleSwipeRight()}
                  testID="job-accept-button"
                >
                  {applyLoading ? <Spinner size={30} /> : <MdCheck fontSize="inherit" />}
                </IconButton>
              </div>
              <div className="mobileControllerLabel" id="mobile-apply-button">
                Apply Now!
              </div>
            </div>
          )}
          <div className="mobileControllerWrapper">
            <div className="mobileControllerBtnWrapper">
              <IconButton
                variant="outlined"
                color="primary"
                aria-describedby="mobile-star-button"
                disabled={actionsDisabled}
                sx={{ width: 42, height: 42 }}
                onClick={() => onStar(currentJob, !isStarred)}
                testID="job-star-button"
              >
                {starLoading ? <Spinner size={24} /> : <MdStarBorder />}
              </IconButton>
            </div>
            <div className="mobileControllerLabel" id="mobile-star-button">
              Save for Later
            </div>
          </div>
        </div>
      )}

      {scheduleModalConfig.isOpen && (
        <EmployeeInterviewScheduleModal
          {...scheduleModalConfig}
          interviewSaveLoading={interviewSaveLoading}
          onClose={closeInterviewModal}
          onConfirm={handleInterviewConfirm}
        />
      )}
    </>
  );
}

JobsSwiper.propTypes = {
  applyLoading: PT.bool,
  onApply: PT.func.isRequired,
  onReject: PT.func.isRequired,
  onRetract: PT.func.isRequired,
  onStar: PT.func.isRequired,
  onJobsRefresh: PT.func.isRequired,
  initialIndex: PT.number,
  interviewSaveLoading: PT.bool,
  jobs: PT.arrayOf(PT.objectOf(PT.any)).isRequired,
  jobsLoading: PT.bool,
  onJobSelect: PT.func,
  onLoadMore: PT.func,
  onSaveInterview: PT.func.isRequired,
  rejectLoading: PT.bool,
  retractLoading: PT.bool,
  starLoading: PT.bool
};

JobsSwiper.defaultProps = {
  applyLoading: false,
  initialIndex: 0,
  interviewSaveLoading: false,
  jobsLoading: false,
  onLoadMore: () => {},
  onJobSelect: () => {},
  rejectLoading: false,
  retractLoading: false,
  starLoading: false
};

export default memo(JobsSwiper);
