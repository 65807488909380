import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { qaAttr, getPlanIdParamFromSearch, getRoutes } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { Box, FormHelperText, styled } from 'components';
import { Button, IconButton, Input, Spinner } from 'components/shared';
import { MdArrowBack, MdEmail } from 'components/icons';
import { useForm } from 'components/form';
import { GoogleAuthButton, FacebookAuthButton } from 'components/Login';
import styles from 'styles/Login';
import { SIGN_UP, RESEND_VERIFICATION_MAIL } from 'api';
import LoginLayout from '../Layouts/LoginLayout';
import { trackPixelEvent } from 'utils/fbpixel';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    event: 'User Signed Up'
  },
}


const StyledLoginLayout = styled(LoginLayout)(styles);

const ROUTES = getRoutes();

const getReferrerLocation = (location) => location?.state?.from?.location || {};

function SignUp() {
  const location = useLocation();
  const navigate = useNavigate();

  const [signUp, { error: sgnUpError, loading: signUpLoading }] = useMutation(SIGN_UP, {
    cachePolicy: 'no-cache'
  });
  const backendError = sgnUpError?.graphQLErrors?.[0]?.message;

  const [resendEmail, { loading: resendLoading }] = useMutation(RESEND_VERIFICATION_MAIL, {
    cachePolicy: 'no-cache'
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [savedEmail, setSavedEmail] = useState('');
  const [emailResendAttemptsCount, setEmailResendAttemptsCount] = useState(0);

  const { $, set, validate } = useForm({
    initial: { email: '', password: '', confirmedPassword: '' },
    validations: {
      email: ['presence', 'email'],
      password: 'presence',
      confirmedPassword: {
        presence: true,
        equality: {
          attribute: 'password',
          message: "Passwords don't match"
        }
      }
    }
  });

  const handleEmailResend = async () => {
    await resendEmail({ variant: { email: savedEmail } });
    setEmailResendAttemptsCount((prev) => prev + 1);
  };

  const handleUserSignUp = async ({ email, password, googleToken, fbToken }) => {
    const { data } = await signUp({
      variables: {
        password,
        email: email.toLowerCase(),
        googleToken,
        fbToken,
        planId: getPlanIdParamFromSearch(
          getReferrerLocation(location).search || location.search || ''
        )
      }
    });
    const { token, userId } = data?.signUp || {};

    setSavedEmail( email.toLowerCase() );
    track('User Signed Up');
    //trigger FBpixel event
    trackPixelEvent('User Signed Up');
    //trigger Google analytics event
    TagManager.dataLayer(tagManagerArgs);

    if (userId && token) {
      localStorage.setItem('user_id', userId);
      localStorage.setItem('token', token);
      navigate(ROUTES.login.default);
    } else {
      const referrer = getReferrerLocation(location)?.pathname;
      // save referrer to localStorage and grab it when user will open verification link from email (see SignIn.jsx)
      if (referrer) localStorage.setItem('post_signup_referrer', JSON.stringify({ referrer }));
      setShowSuccess(true);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    validate().then(({ email, password }) => {
      handleUserSignUp({ password, email });
    });
  };

  const handleInputChange = (e, { name }) => {
    set(name, e.target.value);
  };

  const openEmailClient = (emailClient) => {
    window.open(
      `https://${emailClient === 'yahoo' ? 'login.' : 'www.'}${emailClient}.com`,
      '_blank'
    );
  };

  const renderMailClientLink = (title, clientKey) => (
    <Button
      variant="filled-primary"
      className="signUp-mailClients__link"
      onClick={() => openEmailClient(clientKey)}
    >
      {title}
    </Button>
  );

  const renderSuccess = () => (
    <div className="content">
      <div className="subPageHeader">
        <IconButton isRouterLink to={ROUTES.login.default} color="primary" testID="go-to-sign-in">
          <MdArrowBack color="inherit" />
        </IconButton>
      </div>
      <div className="signUp-verifyContent__body">
        <div className="signUp-verifyIconWrapper">
          <MdEmail fontSize="inherit" color="inherit" />
        </div>
        <h1 className="pageTitle">Verify Email</h1>
        <p className="signUp-verifyText">
          We've sent you an email with a verification link. Please click that link to verify your
          account.
        </p>
        <p className="signUp-verifyText" style={{ fontSize: 12 }}>
          If you do not receive it soon, check your spam folder or{' '}
          <Button
            variant="text"
            className="signUp-resendLink"
            disabled={resendLoading}
            onClick={handleEmailResend}
          >
            resend
          </Button>
          {emailResendAttemptsCount > 0 && (
            <div>
              We have resent the verification email{' '}
              {emailResendAttemptsCount > 1 ? `${emailResendAttemptsCount} times` : ''}
            </div>
          )}
        </p>
        <div className="signUp-mailClients">
          {renderMailClientLink('Open Gmail', 'gmail')}
          {renderMailClientLink('Open Outlook', 'outlook')}
          {renderMailClientLink('Open Yahoo', 'yahoo')}
        </div>
      </div>
    </div>
  );

  const renderMainSignUp = () => (
    <div className="content">
      <div className="contentHeader" />
      <h1 className="pageTitle" {...qaAttr('sign-up-title')}>
        Sign Up
      </h1>
      <div className="centeredBlock">
        <GoogleAuthButton authType="signUp" className="mainButton" onSuccess={handleUserSignUp} />
        <FacebookAuthButton authType="signUp" className="mainButton" onSuccess={handleUserSignUp} />
      </div>
      <form className="centeredBlock" noValidate onSubmit={submitForm}>
        <Input
          id="email"
          {...$('email', handleInputChange)}
          placeholder="Email"
          type="email"
          required
          withHelperText
          highlightInputOnError={false}
          autoComplete="email"
          FormControlProps={{ sx: { mb: '16px' } }}
          analyticParams={{
            key: `Email focused (Sign Up)`,
            trigger: 'focus'
          }}
          testID="sign-up-email-input"
        />
        <Box mb="16px">
          <Input
            id="password"
            {...$('password', handleInputChange)}
            placeholder="Password"
            type="password"
            withHelperText
            highlightInputOnError={false}
            autoComplete="current-password"
            FormControlProps={{ sx: { mb: '16px' } }}
            analyticParams={{
              key: `Password focused (Sign Up)`,
              trigger: 'focus'
            }}
            testID="sign-up-password-input"
          />
          <Input
            id="passwordConfirm"
            {...$('confirmedPassword', handleInputChange)}
            placeholder="Repeat Password"
            type="password"
            withHelperText
            highlightInputOnError={false}
            analyticParams={{
              key: `Password confirmation focused (Sign Up)`,
              trigger: 'focus'
            }}
            testID="sign-up-confirm-password-input"
          />
          {backendError && (
            <FormHelperText error className="helperText">
              {backendError}
            </FormHelperText>
          )}
        </Box>
        <Button
          type="submit"
          variant="filled-primary"
          className="mainButton submitBtn"
          disabled={signUpLoading}
          endIcon={signUpLoading && <Spinner size={12} />}
          testID="sign-up-submit-button"
        >
          Sign Up
        </Button>
        <Button
          isRouterLink
          to={ROUTES.login.newPwd}
          variant="text"
          width="100%"
          sx={{ mb: '16px', fontSize: 14, lineHeight: '22px' }}
          testID="go-to-forgot-password-page"
        >
          Forgot Password
        </Button>
      </form>
      <div className="centeredBlock">
        <Box fontSize={14} textAlign="center" lineHeight="22px">
          <Box component="p" fontWeight="bold">
            Already Have an Account?
          </Box>
          <Button
            isRouterLink
            to={{
              pathname: ROUTES.login.default,
              search: location.search
            }}
            state={{ ...(location?.state?.from ? { from: location.state.from } : {}) }}
            variant="text"
            width="100%"
            sx={{ fontSize: 14, lineHeight: '22px' }}
            testID="go-to-sign-in-page"
          >
            Sign In
          </Button>
        </Box>
      </div>
    </div>
  );

  return (
    <StyledLoginLayout className="container">
      {showSuccess ? renderSuccess() : renderMainSignUp()}
    </StyledLoginLayout>
  );
}

SignUp.propTypes = {};

export default SignUp;
