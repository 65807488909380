import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { getDisplayName } from 'utils';
import { useAlerts } from 'hooks';
import { styled } from 'components';
import { Input } from 'components/shared';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import HireReasonsModal from 'components/dialogs/HireReasonsModal';
import Paylocity from 'components/dialogs/Paylocity';
import { getApplicantUserId } from 'components/ApplicantsDashboard/utils';
import { track } from 'utils/segmentAnalytics';
import { APPROVE_EMPLOYEE, HIRE_EMPLOYEE, ADD_USER_TO_JOBS_NOTES } from 'api';

const REJECT_MESSAGE =
  'Thanks for submitting your application to our open position. Unfortunately, we don’t think it’s a perfect match right now. Please keep an eye out for more roles from us that may be a better fit!';
const ACCEPT_MESSAGE =
  'Thanks for submitting your application to our open position. We like your profile and would like to consider you for the job. When are you available to chat?';
const getAcceptMessageWithLink = (link) =>
  `Congratulations! We would like to formally invite you to the next step in the interview process. Please follow the link to the next step here: ${link}`;

const modalsDefault = {
  acceptModal: false,
  rejectModal: false,
  hireReasonsModal: false,
  paylocity: false,
  applicantData: {},
  jobId: null,
  mergeData: null,
  onSuccess: () => {},
  onCancel: () => {},
  onError: () => {},
  resolve: () => {},
  reject: () => {}
};

const StyledInput = styled(Input)({
  '& .messageInputRoot': {
    padding: 0,
    border: 'none'
  },
  '& .messageInput': {
    padding: 0,
    lineHeight: '24px'
  }
});

function withApplicantActions(WrappedComponent) {
  function WithApplicantActions(props) {
    const { setSimpleAlert } = useAlerts();
    const [modals, setModals] = useState(modalsDefault);
    const [acceptDialogMessage, setAcceptDialogMessage] = useState(ACCEPT_MESSAGE);
    const [rejectDialogMessage, setRejectDialogMessage] = useState(REJECT_MESSAGE);

    const [mergeData1, setMergeData] = useState(null);
    const [paylocityData1, setPaylocityData] = useState(null);

    const {
      resolve: resolveCallback,
      reject: rejectCallback,
      onCancel: modalsCancelCallback
    } = modals;

    const [hireEmployee, { loading: hireLoading = false }] = useMutation(HIRE_EMPLOYEE);
    const [acceptEmployee, { loading: acceptLoading = false }] = useMutation(APPROVE_EMPLOYEE);
    const [rejectEmployee, { loading: rejectLoading = false }] = useMutation(APPROVE_EMPLOYEE);
    const [starEmployee, { loading: starLoading = false }] = useMutation(APPROVE_EMPLOYEE);
    const [cancelEmployee, { loading: cancelLoading = false }] = useMutation(APPROVE_EMPLOYEE);
    const [toNextSteps, { loading: toNextStepsLoading = false }] = useMutation(APPROVE_EMPLOYEE);
    const [postNotesToUser, { loading: postNotesToUserLoading = false }] =
      useMutation(ADD_USER_TO_JOBS_NOTES);

    const manageModals = (newConfig = {}) => {
      setModals((prev) => ({ ...prev, ...newConfig }));
    };

    const openIntegrationAlert = (subtitle) => {
      setSimpleAlert({
        isOpen: true,
        title: 'Notice!',
        subtitle,
        onCancel: () => setSimpleAlert({ isOpen: false })
      });
    };

    const finishHire = useCallback(
      async ({ notes, reasons }) => {
        const { applicantData, hired, jobId, onSuccess, onError } = modals;
        const applicationUID = getApplicantUserId(applicantData);

        manageModals({
          ...modalsDefault,
          acceptModal: false,
          hireReasonsModal: false,
          paylocity: false
        });

        let payload = {
          jobId,
          employeeId: applicationUID,
          notes,
          hired,
          hiredReasons: reasons,
          mergeData: {}
        };
        const hasMerge = localStorage.getItem('hasMerge');
        if (hasMerge === '1') {
          payload = {
            ...payload,
            mergeData: paylocityData1
          };
        }

        if (jobId && applicationUID) {
          try {
            const response = await hireEmployee({
              variables: payload
            });

            if (response.data.hiredEmployee.integrationResponse) {
              let alertSubTitle = '';
              if (
                response.data.hiredEmployee.integrationResponse.status &&
                (response.data.hiredEmployee.integrationResponse.status === 'success' ||
                  response.data.hiredEmployee.integrationResponse.status === '201')
              ) {
                alertSubTitle = 'Employee successfully posted to HR system';
              } else {
                alertSubTitle = 'Employee did not post to HR system';
              }
              const alertresp = await openIntegrationAlert(alertSubTitle);
            }

            onSuccess(response);
          } catch (error) {
            console.error(error);
            onError(error);
          }
        }
      },
      [
        modals.hired,
        modals.jobId,
        JSON.stringify(modals.applicantData),
        modals.onSuccess,
        paylocityData1
      ]
    );

    const finishAccept = useCallback(async () => {
      const { applicantData, jobId, onSuccess, onError } = modals;
      const applicantUID = getApplicantUserId(applicantData);

      manageModals({ ...modalsDefault, acceptModal: false, hireReasonsModal: false });

      if (jobId && applicantUID) {
        try {
          const response = await acceptEmployee({
            variables: {
              jobId,
              userId: applicantUID,
              approved: true,
              passed: false,
              starred: false,
              nextSteps: false,
              message: acceptDialogMessage
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      }
    }, [acceptDialogMessage, modals.jobId, JSON.stringify(modals.applicantData), modals.onSuccess]);

    const finishReject = useCallback(async () => {
      const { applicantData, jobId, onSuccess, onError } = modals;
      const applicantUID = getApplicantUserId(applicantData);

      manageModals({
        ...modalsDefault,
        rejectModal: false,
        hireReasonsModal: false,
        paylocity: false
      });

      if (jobId && applicantUID) {
        try {
          const response = await rejectEmployee({
            variables: {
              jobId,
              userId: applicantUID,
              approved: false,
              passed: true,
              starred: false,
              nextSteps: false,
              message: rejectDialogMessage
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      }
    }, [rejectDialogMessage, modals.jobId, JSON.stringify(modals.applicantData), modals.onSuccess]);

    const openAcceptDialog = useCallback((params) => {
      manageModals({ acceptModal: true, ...params });
    }, []);

    const openRejectDialog = useCallback((params) => {
      manageModals({ rejectModal: true, ...params });
    }, []);

    const closeRejectDialog = useCallback((params) => {
      manageModals({ rejectModal: false, ...params });
    }, []);

    const closeAcceptDialog = useCallback((params) => {
      manageModals({ acceptModal: false, ...params });
    }, []);

    const openHireReasonsModal = useCallback((params) => {
      manageModals({ hireReasonsModal: true, ...params });
    }, []);

    const closeHireReasonsModal = useCallback((params) => {
      manageModals({ hireReasonsModal: false, ...params });
    }, []);

    const openPaylocity = useCallback((params) => {
      manageModals({ paylocity: true, ...params });
    }, []);

    const closePaylocity = useCallback((params) => {
      manageModals({ paylocity: false, ...params });
    }, []);

    const handlePaylocityData = useCallback(
      async ({ start_date, tax_form, pay_type, employment_type, costCenter1, costCenter2 }) => {
        const { applicantData, hired, jobId, onSuccess, onError, onCancel } = modals;
        manageModals({ ...modalsDefault, paylocity: false });

        const paylocityD = {
          startDate: start_date,
          taxForm: tax_form,
          payType: pay_type,
          employmentType: employment_type,
          costCenter1,
          costCenter2
        };
        setPaylocityData(paylocityD);

        openHireReasonsModal({
          applicantData,
          hired: true,
          jobId,
          onSuccess,
          onCancel,
          onError
        });
      },
      [
        modals.jobId,
        JSON.stringify(modals.applicantData),
        JSON.stringify(modals.mergeData),
        modals.onSuccess
      ]
    );

    const openPreHireAlert = useCallback(
      (params) =>
        new Promise((resolve, reject) => {
          setSimpleAlert({
            isOpen: true,
            title: 'Alert!',
            subtitle:
              'Moving the candidate to the ‘hired’ section will count this candidate as part of your team on our platform. If you do not want this, please move them to ‘Next Steps’',
            onSuccess: () => {
              resolve({ confirmed: true });
              track('Marked Applicant Hired');
            },
            onCancel: () => {
              resolve({ confirmed: false });
              setSimpleAlert({ isOpen: false });
            }
          });
        }),
      [setSimpleAlert]
    );

    const acceptApplicant = useCallback(
      async ({
        applicantData,
        jobId,
        jobWebUrl,
        onSuccess = () => {},
        onCancel = () => {},
        onError = () => {}
      }) => {
        if (jobWebUrl) {
          setAcceptDialogMessage(getAcceptMessageWithLink(jobWebUrl));
          openAcceptDialog({ applicantData, jobId, onSuccess, onCancel, onError });
        } else {
          const hasMerge = localStorage.getItem('hasMerge');
          const response = await openPreHireAlert();
          if (response?.confirmed) {
            if (hasMerge === '1') {
              const mergeRes = await openPaylocity({
                applicantData,
                hired: true,
                jobId,
                onSuccess,
                onCancel,
                onError
              });
            } else {
              openHireReasonsModal({
                applicantData,
                hired: true,
                jobId,
                onSuccess,
                onCancel,
                onError
              });
            }
          } else {
            onCancel();
          }
        }
      },
      [openHireReasonsModal, openAcceptDialog, openPreHireAlert, openPaylocity]
    );

    const rejectApplicant = useCallback(
      ({
        applicantData,
        jobId,
        jobWebUrl,
        onSuccess = () => {},
        onCancel = () => {},
        onError = () => {}
      }) => {
        if (jobWebUrl) {
          openRejectDialog({ applicantData, jobId, onSuccess, onCancel, onError });
        } else {
          openHireReasonsModal({
            applicantData,
            hired: false,
            jobId,
            onSuccess,
            onCancel,
            onError
          });
        }
      },
      [openRejectDialog, openHireReasonsModal]
    );

    const starApplicant = useCallback(
      async ({ applicantData, jobId, starred, onSuccess = () => {}, onError = () => {} }) => {
        const applicantUID = getApplicantUserId(applicantData);

        try {
          const response = await starEmployee({
            variables: {
              userId: applicantUID,
              jobId,
              starred
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      },
      [starEmployee]
    );

    const cancelApplicant = useCallback(
      async ({ applicantData, jobId, onSuccess = () => {}, onError = () => {} }) => {
        const applicantUID = getApplicantUserId(applicantData);

        try {
          const response = await cancelEmployee({
            variables: {
              jobId,
              userId: applicantUID,
              approved: false,
              passed: false,
              starred: false,
              nextSteps: false
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      },
      [cancelEmployee]
    );

    const moveApplicantToNextSteps = useCallback(
      async ({ applicantData, jobId, onSuccess = () => {}, onError = () => {} }) => {
        const applicantUID = getApplicantUserId(applicantData);

        try {
          const response = await toNextSteps({
            variables: {
              userId: applicantUID,
              jobId,
              nextSteps: true
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      },
      [toNextSteps]
    );

    const saveUserToJobsNotes = useCallback(
      async ({ applicantData, jobId, notes, onSuccess = () => {}, onError = () => {} }) => {
        const applicantUID = getApplicantUserId(applicantData);

        try {
          const response = await postNotesToUser({
            variables: {
              userToJobsNotes: notes,
              userId: applicantUID,
              jobId
            }
          });

          onSuccess(response);
          return response;
        } catch (error) {
          console.error(error);
          onError(error);
        }
      },
      [postNotesToUser]
    );

    return (
      <>
        <WrappedComponent
          {...props}
          acceptApplicant={acceptApplicant}
          rejectApplicant={rejectApplicant}
          starApplicant={starApplicant}
          cancelApplicant={cancelApplicant}
          moveToNextSteps={moveApplicantToNextSteps}
          saveUserToJobsNotes={saveUserToJobsNotes}
          hireLoading={hireLoading}
          acceptLoading={acceptLoading}
          rejectLoading={rejectLoading}
          starLoading={starLoading}
          cancelLoading={cancelLoading}
          moveToNextStepsLoading={toNextStepsLoading}
        />

        <ConfirmationDialog
          isOpen={modals.acceptModal}
          title="Accept Message"
          message={
            <StyledInput
              value={acceptDialogMessage}
              multiline
              autoFocus
              inputClassName="messageInputRoot"
              htmlInputClassName="messageInput"
              onChange={(e) => setAcceptDialogMessage(e.target.value)}
              testID="candidates-table-accept-input"
            />
          }
          onClose={() => {
            modals.onCancel();
            closeAcceptDialog();
          }}
          onConfirm={finishAccept}
          onCancel={() => {
            modals.onCancel();
            closeAcceptDialog();
          }}
        />

        <ConfirmationDialog
          isOpen={modals.rejectModal}
          title="Reject Message"
          message={
            <StyledInput
              value={rejectDialogMessage}
              multiline
              autoFocus
              inputClassName="messageInputRoot"
              htmlInputClassName="messageInput"
              onChange={(e) => setRejectDialogMessage(e.target.value)}
              testID="candidates-table-reject-input"
            />
          }
          onClose={() => {
            modals.onCancel();
            closeRejectDialog();
          }}
          onConfirm={finishReject}
          onCancel={() => {
            modals.onCancel();
            closeRejectDialog();
          }}
        />

        <Paylocity
          isOpen={modals.paylocity}
          title="Paylocity Data Needed"
          hired={modals.paylocity}
          jobId={modals.jobId}
          onClose={() => {
            modals.onCancel();
            closePaylocity();
          }}
          onConfirm={handlePaylocityData}
        />

        <HireReasonsModal
          isOpen={modals.hireReasonsModal}
          title={modals.hired ? 'Hire reasons' : 'Deny reasons'}
          hired={modals.hired}
          onClose={() => {
            modals.onCancel();
            closeHireReasonsModal();
          }}
          onConfirm={finishHire}
        />
      </>
    );
  }

  WithApplicantActions.displayName = `WithApplicantActions(${getDisplayName(WrappedComponent)})`;
  return WithApplicantActions;
}

export default withApplicantActions;
