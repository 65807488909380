import React, { useState, useEffect, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { qaAttr } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { Box, styled } from 'components';
import { CheckBox, Button, IconButton, Spinner, Select } from 'components/shared';
import { useForm } from 'components/form';
import { MdInfo } from 'components/icons';
import useGrowJobTypes from './useGrowJobTypes';

const mobileLabelStyles = (theme) => ({
  [theme.breakpoints.down('desktopApp')]: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '150%',
    color: theme.palette.moreColors.grey_3
  }
});

const StyledRoot = styled('div')(({ theme }) => ({
  '&.positionForm': {},
  '& .positionForm__selectContainer': {
    width: '100%',
    marginBottom: 16,
    '& .StyledInput-label': {
      marginBottom: 7,
      fontSize: 10,
      color: '#ECECF5', // @TODO: get from theme
      '&.Mui-error': {
        color: theme.palette.moreColors.pink
      },
      ...mobileLabelStyles(theme)
    },
    '& .StyledInput-inputBase': {
      borderColor: theme.palette.moreColors.purpleLight_2,
      fontSize: 11,
      color: theme.palette.common.white,
      borderRadius: 4,
      '&.Mui-error': {
        borderColor: theme.palette.moreColors.pink
      },
      '&.Mui-disabled': {
        opacity: 0.5
      },
      [theme.breakpoints.down('desktopApp')]: {
        fontSize: 18,
        fontWeight: 800,
        lineHeight: '150%',
        borderRadius: 8
      }
    },
    '& .StyledInput-inputBase__input': {
      // '&.Mui-disabled': {
      //   '-webkit-text-fill-color': '#FFF'
      // }
      [theme.breakpoints.down('desktopApp')]: {
        padding: '10px 12px'
      }
    },
    '& .StyledSelect-select__icon': {
      color: theme.palette.common.white
    },
    '& .StyledSelect-helperText': {
      fontSize: 12,
      '&.Mui-error': {
        color: theme.palette.moreColors.pink
      }
    }
  },
  '& .positionForm__select': {},
  '& .positionForm__checkbox': {
    color: '#ECECF5', // @TODO: get from theme
    '&:not(:last-of-type)': {
      marginBottom: 16
    },
    '& .StyledCheckbox-checkbox': {
      borderColor: theme.palette.moreColors.purpleLight_2,
      '&.Mui-checked': {
        color: theme.palette.common.white
      }
    },
    '& .StyledCheckbox-label': {
      marginLeft: 10,
      fontSize: 11,
      ...mobileLabelStyles(theme)
    }
  }
}));

function PositionForm(props) {
  const {
    currentlySearching,
    curJobTypeId,
    futureJobTypeId,
    industryId,
    loading,
    onChange,
    showEmployerData
  } = props;
  const {
    getJobTypesByIndustryId,
    getPathGroups,
    jobTypesByIndustry,
    currentJobTypesOptions,
    futureJobTypesOptions,
    typesLoading,
    isPathGroupExist
  } = useGrowJobTypes({ curJobTypeId, futureJobTypeId });

  const { $, attrs, set, getError } = useForm({
    initial: {
      showEmployerData: showEmployerData || false,
      currentlySearching: currentlySearching || false,
      curJobTypeId: curJobTypeId || '',
      futureJobTypeId: futureJobTypeId || ''
    }
  });

  useEffect(() => {
    if (industryId) getJobTypesByIndustryId(industryId);
  }, [industryId]);

  useEffect(() => {
    set({
      showEmployerData: showEmployerData || false,
      currentlySearching: currentlySearching || false,
      curJobTypeId: curJobTypeId || '',
      futureJobTypeId: futureJobTypeId || ''
    });
  }, [showEmployerData, currentlySearching, curJobTypeId, futureJobTypeId]);

  const handleChange = (e, { name }) => {
    const { value } = e.target;
    set(name, value);
    onChange({ ...attrs, [name]: value });
  };

  const handleCheckboxChange = (e, { name }) => {
    const { checked } = e.target;
    set(name, checked);
    onChange({ ...attrs, [name]: checked });
  };

  return (
    <StyledRoot className="positionForm">
      <Select
        id="grow-current-job"
        {...$('curJobTypeId', handleChange)}
        label="Where I'm at:"
        required
        disabled={currentlySearching || loading}
        inputVariant="outlined"
        className="positionForm__selectContainer"
        options={currentJobTypesOptions.map((opt, i) => {
          if (opt.delimiter) {
            return {
              key: `curJob__delimiter-${i}`,
              ...opt.optionProps
            };
          }
          return {
            key: `curJob__${opt.id}`,
            ...opt.optionProps,
            ...qaAttr(`wt-grow-current-job-option-${opt.id}`)
          };
        })}
        emptyOption={
          <Box component="option" disabled value="" color="rgba(0,0,0,0.5)">
            Current Job
          </Box>
        }
        testID="wt-grow-current-job-select"
      />
      <Select
        id="grow-future-job"
        {...$('futureJobTypeId', handleChange)}
        error={
          !isPathGroupExist ? (
            <>
              <MdInfo color="inherit" fontSize="inherit" /> The track is coming soon. Select a
              different track to get started today.
            </>
          ) : (
            getError('futureJobTypeId')
          )
        }
        withHelperText
        label="Where I'm going:"
        required
        disabled={loading}
        className="positionForm__selectContainer"
        inputVariant="outlined"
        options={futureJobTypesOptions.map((opt, i) => {
          if (opt.delimiter) {
            return {
              key: `futureJob__delimiter-${i}`,
              ...opt.optionProps
            };
          }
          return {
            key: `futureJob__${opt.id}`,
            ...opt.optionProps,
            ...qaAttr(`wt-grow-future-job-option-${opt.id}`)
          };
        })}
        emptyOption={
          <Box component="option" disabled value="" color="rgba(0,0,0,0.5)">
            Future Job
          </Box>
        }
        testID="wt-grow-future-job-select"
      />
      <CheckBox
        {...$('currentlySearching', handleCheckboxChange)}
        checked={attrs.currentlySearching}
        label="Currently searching for a career"
        disabled={loading}
        className="positionForm__checkbox"
        testID="wt-grow-currently-searching"
      />
      <CheckBox
        {...$('showEmployerData', handleCheckboxChange)}
        checked={attrs.showEmployerData}
        label="Employer can see my stats"
        disabled={loading}
        className="positionForm__checkbox"
        testID="wt-grow-show-stats"
      />
    </StyledRoot>
  );
}

PositionForm.propTypes = {
  currentlySearching: PT.bool.isRequired,
  curJobTypeId: PT.oneOfType([PT.any, PT.number]),
  futureJobTypeId: PT.oneOfType([PT.any, PT.number]),
  industryId: PT.number.isRequired,
  loading: PT.bool,
  onChange: PT.func,
  showEmployerData: PT.bool.isRequired
};

PositionForm.defaultProps = {
  curJobTypeId: null,
  futureJobTypeId: null,
  loading: false,
  onChange: () => {}
};

export default memo(PositionForm);
