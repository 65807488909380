import React from 'react';
import PT from 'prop-types';
import { FormControl, FormHelperText, InputLabel, InputBase, styled } from 'components';
import { track } from 'utils/segmentAnalytics';
import { qaAttr } from 'utils';

const PREFIX = 'StyledInput';
const classes = {
  inputBase: `${PREFIX}-inputBase`,
  inputBaseInput: `${PREFIX}-inputBase__input`,
  label: `${PREFIX}-label`,
  labelFormControl: `${PREFIX}-label__formControl`,
  helperText: `${PREFIX}-helperText`
};

const Input = React.forwardRef((props, ref) => {
  const {
    analyticParams,
    ariaLabel,
    className,
    disabled,
    FormControlProps,
    FormHelperTextProps,
    error,
    helperText,
    helperTextClassName,
    highlightInputOnError,
    htmlInputClassName,
    id,
    InputProps,
    inputClassName,
    inputProps,
    InputLabelProps,
    label,
    labelClassName,
    name,
    onFocus,
    required,
    testID,
    variant,
    withHelperText,
    ...rest
  } = props;
  const isError = !!error;
  const helperId = id || name ? `${id || name}-helper` : '';

  const handleFocus = (e) => {
    if (onFocus) onFocus(e);
  };

  return (
    <FormControl
      fullWidth
      required={required}
      disabled={disabled}
      error={isError}
      className={className}
      {...FormControlProps}
    >
      {label && (
        <Label shrink className={labelClassName} {...(id && { htmlFor: id })} {...InputLabelProps}>
          {label}
        </Label>
      )}
      <InputComponent
        id={id}
        name={name}
        className={inputClassName}
        inputProps={{
          className: htmlInputClassName,
          ...(testID && qaAttr(testID)),
          ...(name && !label && { 'aria-label': name }),
          ...(ariaLabel && { 'aria-label': ariaLabel }),
          ...inputProps
        }}
        inputRef={ref}
        aria-describedby={withHelperText ? helperId : ''}
        onFocus={handleFocus}
        variant={variant}
        highlightInputOnError={highlightInputOnError}
        {...InputProps}
        {...rest}
      />
      {withHelperText && (helperText || error) && (
        <InputMessage id={helperId} className={helperTextClassName} {...FormHelperTextProps}>
          {helperText || error}
        </InputMessage>
      )}
    </FormControl>
  );
});

Input.propTypes = {
  ariaLabel: PT.string,
  analyticParams: PT.shape({
    trigger: PT.oneOf(['focus']),
    key: PT.string,
    params: PT.objectOf(PT.any)
  }),
  className: PT.string, // container className
  disabled: PT.bool,
  FormControlProps: PT.objectOf(PT.any), // container props (Mui FormControl)
  FormHelperTextProps: PT.objectOf(PT.any), // Mui HelperText props
  error: PT.string,
  helperText: PT.string, // message (not error)
  helperTextClassName: PT.string,
  htmlInputClassName: PT.string,
  highlightInputOnError: PT.bool,
  id: PT.oneOfType([PT.string, PT.number]),
  inputProps: PT.objectOf(PT.any), // html input
  InputProps: PT.objectOf(PT.any), // wrapper around html input (Mui InputBase)
  inputClassName: PT.string,
  InputLabelProps: PT.objectOf(PT.any),
  label: PT.oneOfType([PT.string, PT.node]),
  labelClassName: PT.string,
  name: PT.string,
  onFocus: PT.func,
  required: PT.bool,
  testID: PT.string,
  variant: PT.oneOf(['', 'text', 'outlined', 'textfield', '  ']),
  withHelperText: PT.bool // show message
};

Input.defaultProps = {
  ariaLabel: '',
  analyticParams: { key: '', trigger: 'focus' },
  className: '',
  disabled: false,
  FormControlProps: {},
  FormHelperTextProps: {},
  error: '',
  helperText: '',
  helperTextClassName: '',
  htmlInputClassName: '',
  highlightInputOnError: true,
  id: '',
  inputProps: {},
  InputProps: {},
  inputClassName: '',
  InputLabelProps: {},
  label: '',
  labelClassName: '',
  name: '',
  onFocus: () => {},
  required: false,
  testID: '',
  variant: 'outlined',
  withHelperText: false
};

const InputComponent = styled(
  ({ className, ...props }) => (
    <InputBase
      className={className}
      classes={{
        root: classes.inputBase,
        input: classes.inputBaseInput
      }}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'highlightInputOnError'
  }
)(({ theme, variant, highlightInputOnError }) => ({
  fontSize: 16,
  lineHeight: '17px',
  color: theme.palette.moreColors.black,
  ...(variant === 'outlined' && {
    border: '1px solid rgba(255,255,255,0.2)',
    borderRadius: 10
  }),
  ...(variant === 'textfield' && {
    borderBottom: '1px solid rgba(255,255,255,0.2)'
  }),
  ...(variant === 'text' && {
    padding: 0,
    border: 'none',
    '&.Mui-disabled': {
      color: theme.palette.moreColors.black
    }
  }),
  '&.Mui-error': {
    ...(highlightInputOnError && { borderColor: theme.palette.error.main })
  },
  [`& .${classes.inputBaseInput}`]: {
    height: 'auto',
    borderRadius: 'inherit',
    ...(variant === 'outlined' && {
      padding: 16
    }),
    ...(variant === 'textfield' && {
      padding: '6px 0'
    }),
    ...(variant === 'text' && {
      padding: 0
    })
  }
}));

const Label = styled(({ className, ...props }) => (
  <InputLabel
    className={className}
    classes={{
      root: classes.label,
      formControl: classes.labelFormControl
    }}
    {...props}
  />
))(({ theme }) => ({
  marginBottom: 4,
  position: 'static',
  transform: 'none',
  fontSize: 14,
  lineHeight: '17px',
  color: theme.palette.primary.text,
  [`&.${classes.label}.Mui-error`]: {
    // increased specificity to prevent overriding
    color: theme.palette.error.main
  },
  [`& .${classes.labelFormControl}`]: {
    '&[data-shrink=false]': {
      '& + .MuiInputBase-root .MuiInputBase-input': {
        '&::placeholder': {
          opacity: '0.4 !important'
        }
      }
    }
  },
  '& .MuiInputLabel-asterisk': {
    color: theme.palette.error.main
  }
}));

const InputMessage = styled(({ className, ...props }) => (
  <FormHelperText
    className={className}
    classes={{
      root: classes.helperText
    }}
    {...props}
  />
))(({ theme }) => ({
  marginTop: 6,
  fontSize: 14,
  fontWeight: 'bold',
  lineHeight: '17px'
}));

export default Input;
