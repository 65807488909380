import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import { getDisplayName, getUserId, urlWithHttp } from 'utils';
import { useEmployeeJobCommands } from 'hooks';
import { Box } from 'components';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import JobQuestionsDialog from 'components/dialogs/JobQuestionsDialog';
import EmployeeInterviewCancelAlert from 'components/dialogs/EmployeeInterviewCancelAlert';
import EmployeeInterviewRescheduleAlert from 'components/dialogs/EmployeeInterviewRescheduleAlert';
import { track } from 'utils/segmentAnalytics';
import { trackPixelEvent } from 'utils/fbpixel';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    event: 'Apply to Job'
  },
}

const CANCEL_LIMIT = 1; // max 2
const INIT_MODALS_CONFIG = {
  applyConfirmationModal: false,
  questionsModal: false,
  webLinkModal: false,
  cancelInterviewAlert: false,
  rescheduleAlert: false,
  onClose: () => {},
  onCancel: () => {},
  onSuccess: () => {}
};
const MODAL_RESOLVE_REASONS = {
  confirmed: 'confirmed',
  canceled: 'canceled',
  closed: 'closed'
};

export default (WrappedComponent) => {
  function WithEmployeeJobActions({ ...props }) {
    const {
      applyJob,
      applyLoading,
      interviewSaveLoading,
      rejectJob,
      rejectLoading,
      retractJob,
      retractLoading,
      saveInterview,
      starJob,
      starLoading
    } = useEmployeeJobCommands();

    const [modalsConfig, setModalsConfig] = useState(INIT_MODALS_CONFIG);
    const [job, setJob] = useState(null);

    const manageModals = useCallback(
      (params) => setModalsConfig((prev) => ({ ...prev, ...params })),
      []
    );

    const openModal = useCallback(
      (modalName, { ...config }) =>
        new Promise((resolve) => {
          manageModals({
            [modalName]: true,
            onSuccess: (...successArgs) => {
              resolve({ status: MODAL_RESOLVE_REASONS.confirmed, successArgs });
              manageModals({ [modalName]: false });
            },
            onCancel: (...cancelArgs) => {
              resolve({ status: MODAL_RESOLVE_REASONS.canceled, cancelArgs });
              manageModals({ [modalName]: false });
            },
            onClose: () => {
              resolve({ status: MODAL_RESOLVE_REASONS.closed });
              manageModals({ [modalName]: false });
            },
            ...config
          });
        }),
      [manageModals]
    );

    const questionsModalOpener = useCallback(
      async ({ onFinish, onPass, onClose }) => {
        const { status, successArgs = [] } = await openModal('questionsModal');
        const [answers] = successArgs;

        if (status === MODAL_RESOLVE_REASONS.confirmed) {
          onFinish(answers);
        } else if (status === MODAL_RESOLVE_REASONS.canceled) {
          onPass();
        } else if (status === MODAL_RESOLVE_REASONS.closed) {
          onClose();
        }
      },
      [openModal]
    );

    const prepareJobRetract = useCallback(
      async (selectedJob, options) => {
        const { scheduleStatusCount, userInterviewDate, isSchedule } = selectedJob;

        if (userInterviewDate) {
          const cancelInterviewAlert = await openModal('cancelInterviewAlert');

          if (cancelInterviewAlert.status === MODAL_RESOLVE_REASONS.confirmed) {
            if (scheduleStatusCount >= CANCEL_LIMIT) {
              const rescheduleAlert = await openModal('rescheduleAlert');

              if (rescheduleAlert.status === MODAL_RESOLVE_REASONS.confirmed) {
                retractJob(selectedJob, options);
              }
            } else {
              retractJob(selectedJob, options);
            }
          }
        } else {
          retractJob(selectedJob, options);
        }
      },
      [openModal, retractJob]
    );

    const prepareInterviewReschedule = useCallback(
      async (selectedJob, options) => {
        const { onReschedule } = options;
        const { scheduleStatusCount, userInterviewDate, isSchedule } = selectedJob;

        if (scheduleStatusCount >= CANCEL_LIMIT) {
          const rescheduleAlert = await openModal('rescheduleAlert');

          if (rescheduleAlert.status === MODAL_RESOLVE_REASONS.confirmed) onReschedule();
        } else {
          onReschedule();
        }
      },
      [openModal]
    );

    const applyWithWebUrlAlert = useCallback(
      async (selectedJob, options) => {
        applyJob(selectedJob, options);

        if (selectedJob.webUrl) {
          const { status } = await openModal('webLinkModal');
          if (status === MODAL_RESOLVE_REASONS.confirmed) {
            window.open(urlWithHttp(selectedJob.webUrl), '__blank', 'noopener, noreferrer');
          }
        }
      },
      [applyJob, openModal]
    );

    const prepareJobApply = useCallback(
      async (selectedJob, options) => {
        const { showSchedule, ...restOptions } = options || {};
        const { onCancel } = restOptions;
        const { isSchedule, questions } = selectedJob;
        const filledQuestions = (questions || []).map((q) => q.name && q.name.trim().length > 0);
        setJob(selectedJob);

        if (isSchedule) {
          if (filledQuestions.length) {
            questionsModalOpener({
              onFinish: (answers) => {
                if (answers.filter((a) => !a).length) {
                  // apply without schedule if negative answers exist
                  applyWithWebUrlAlert(selectedJob, restOptions);
                  //trigger segment event
                  track('Apply to Job');
                  //trigger FBpixel event
                  trackPixelEvent('Apply to Job');
                  // //trigger Google analytics event
                  TagManager.dataLayer(tagManagerArgs);
                } else {
                  // show schedule
                  showSchedule();
                }
              },
              onPass: () => rejectJob(selectedJob, restOptions),
              onClose: () => (onCancel ? onCancel() : undefined)
            });
          } else {
            // show schedule
            showSchedule();
          }
        } else {
          const confirmModal = await openModal('applyConfirmationModal');

          if (confirmModal.status === MODAL_RESOLVE_REASONS.confirmed && filledQuestions.length) {
            questionsModalOpener({
              onFinish: () => {applyWithWebUrlAlert(selectedJob, restOptions); track('Apply to Job w/ answers');},
              onPass: () => rejectJob(selectedJob, restOptions),
              onClose: () => {
                if (onCancel) onCancel();
              }
            });
          } else if (confirmModal.status === MODAL_RESOLVE_REASONS.confirmed) {
            applyWithWebUrlAlert(selectedJob, restOptions);
            //trigger segment event
            track('Apply to Job');
            //trigger FBpixel event
            trackPixelEvent('Apply to Job');
            // //trigger Google analytics event
            TagManager.dataLayer(tagManagerArgs);
          } else if (
            confirmModal.status === MODAL_RESOLVE_REASONS.canceled ||
            confirmModal.status === MODAL_RESOLVE_REASONS.closed
          ) {
            if (onCancel) onCancel();
          }
        }
      },
      [questionsModalOpener, applyWithWebUrlAlert, rejectJob, openModal]
    );

    return (
      <>
        <WrappedComponent
          onReject={rejectJob}
          onApply={prepareJobApply}
          onStar={starJob}
          onRetract={prepareJobRetract}
          onSaveInterview={saveInterview}
          rejectLoading={rejectLoading}
          applyLoading={applyLoading}
          starLoading={starLoading}
          retractLoading={retractLoading}
          interviewSaveLoading={interviewSaveLoading}
          prepareInterviewReschedule={prepareInterviewReschedule}
          {...props}
        />

        <ConfirmationDialog
          isOpen={modalsConfig.applyConfirmationModal}
          title="Apply to job?"
          message={
            modalsConfig.applyConfirmationModal ? (
              <Box textAlign="center">{`Do you want to apply for ${job?.title}?`}</Box>
            ) : null
          }
          confirmBtnName="Ok"
          onConfirm={modalsConfig.onSuccess}
          onCancel={modalsConfig.onCancel}
          onClose={modalsConfig.onClose}
        />

        <ConfirmationDialog
          isOpen={modalsConfig.webLinkModal}
          title="External Job Weblink"
          message={
            job?.webUrl ? (
              <Box textAlign="center">
                {`By pressing Ok you agree to be redirected to the external website with selected job: ${job.webUrl}`}
              </Box>
            ) : null
          }
          confirmBtnName="Ok"
          onConfirm={modalsConfig.onSuccess}
          onCancel={modalsConfig.onCancel}
          onClose={modalsConfig.onClose}
        />

        <JobQuestionsDialog
          isOpen={modalsConfig.questionsModal}
          employer={job?.employerProfile?.name}
          questions={job?.questions || []}
          onConfirm={modalsConfig.onSuccess}
          onCancel={modalsConfig.onCancel}
          onClose={modalsConfig.onClose}
        />

        <EmployeeInterviewCancelAlert
          isOpen={modalsConfig.cancelInterviewAlert}
          onConfirm={modalsConfig.onSuccess}
          onCancel={modalsConfig.onCancel}
          onClose={modalsConfig.onClose}
        />

        <EmployeeInterviewRescheduleAlert
          isOpen={modalsConfig.rescheduleAlert}
          onConfirm={modalsConfig.onSuccess}
          onCancel={modalsConfig.onCancel}
          onClose={modalsConfig.onClose}
        />
      </>
    );
  }

  WithEmployeeJobActions.displayName = `WithEmployeeJobActions(${getDisplayName(
    WrappedComponent
  )})`;
  WithEmployeeJobActions.propTypes = {};

  return WithEmployeeJobActions;
};
