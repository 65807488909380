import React, { useState, useEffect, useCallback, useMemo, useRef, memo } from 'react';
import PT from 'prop-types';
import { track } from 'utils/segmentAnalytics';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { nanoid } from 'nanoid/non-secure';
import clsx from 'clsx';
import { map, find } from 'lodash';
import { format, parse as parseDate, isValid as isValidDate } from 'date-fns';
import {
  useEmployerProfileQuery,
  useCountryStateQuery,
  useJobTypesQuery,
  useCurrenciesQuery,
  usePayPeriodsQuery,
  useMatchedRoutes,
  useCombinedJobQuery,
  useNotifications,
  useMediaQueryMatches
} from 'hooks';
import { INTERVIEW_TYPES, getRoutes, qaAttr, trimStr, unformatPhone } from 'utils';
import { Box, FormHelperText, styled } from 'components';
import { Button, DatePicker, Input, PhoneInput, Select, Spinner } from 'components/shared';
import { ConfirmationDialog } from 'components/dialogs';
import { useForm } from 'components/form';
import {
  ToggleJobActiveState,
  EditableJobQuestions,
  EditableJobSkills,
  EditableJobIndustries,
  EditableJobImage
} from 'components/Job';
import styles from 'styles/Dashboard/JobPosting';
import { POST_JOB, ADD_JOB, DELETE_JOB, POST_EMPLOYER_QUESTION, UPDATE_JOB_IMG } from 'api';

const StyledRoot = styled('div')(styles);

const MAX_DESCR_LENGTH = 350;
const MAX_REQ_LENGTH = 250;
const ROUTES = getRoutes();
const INIT_ADDRESS_FORM = {
  city: '',
  countryId: 1, // USA
  stateId: '',
  zip: ''
};
const INIT_PAY_FORM = {
  payCurrencyId: 1, // usd
  payPeriodId: 5, // hourly
  payMin: 0,
  payMax: 0
};
const INITIAL_FIELDS = {
  ...INIT_ADDRESS_FORM,
  ...INIT_PAY_FORM,
  active: false,
  applicantAmount: 0,
  description: '',
  draft: false,
  endDate: null,
  hireDate: null,
  imageUrl: '',
  industry: [],
  interviewType: '',
  jobTypeId: '',
  location: '',
  newImageFile: null,
  questions: [],
  requirements: '',
  skills: [],
  title: '',
  webUrl: ''
};
const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
const FRONT_DATE_FORMAT = 'MM-dd-yyyy';
const serverDateToDateObj = (string) => parseDate(string, SERVER_DATE_FORMAT, new Date());

const INTERVIEW_TYPES_OPTIONS = [
  { value: INTERVIEW_TYPES.inperson, label: 'In-Person' },
  { value: INTERVIEW_TYPES.web, label: 'Web' },
  { value: INTERVIEW_TYPES.phone, label: 'Phone' }
];

const dropTypeName = ({ __typename, ...rest }) => ({ ...rest });

function JobPosting(props) {
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { show: showNotification } = useNotifications();

  const { isEmployerNewJobPage: isNewJobPage, isEmployerEditJobPage: isEditJobPage } =
    useMatchedRoutes();

  const imageEditorRef = useRef();

  const { fetchFullJob, refetchFullJob, fullJob, questionsQueryResult, jobQueryResult } =
    useCombinedJobQuery();
  const { loading: questionsLoading } = questionsQueryResult;
  const { loading: jobLoading } = jobQueryResult;

  const { refetch: refetchEmployerProfile, profile, canPostJob } = useEmployerProfileQuery();
  const canPost = isNewJobPage || fullJob?.job?.draft ? canPostJob : true;
  const canCopy = canPostJob;
  const canSaveDraft = isNewJobPage ? canPost : true;
  const canActivate = !profile?.active && canPostJob;

  const { fetchCountryState, getCountryById, getStateById, states, countries } =
    useCountryStateQuery();
  const { payPeriods, getPayPeriodById } = usePayPeriodsQuery();
  const { currencies, getCurrencyById } = useCurrenciesQuery();
  const { getJobTypes, jobTypes, jobTypesLoading } = useJobTypesQuery();

  const [createJob, { loading: createJobLoading }] = useMutation(ADD_JOB);
  const [updateJob, { loading: updateJobLoading }] = useMutation(POST_JOB);
  const [deleteJob, { loading: deleteJobLoading }] = useMutation(DELETE_JOB);
  const [updateQuestions, { loading: updateQuestionsLoading }] =
    useMutation(POST_EMPLOYER_QUESTION);
  const [updateJobImg, { loading: updateImgLoading }] = useMutation(UPDATE_JOB_IMG);

  const [isEdit, setIsEdit] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);
  const [isImageChanged, setImageChanged] = useState(false);

  const jobProcessing =
    createJobLoading ||
    updateJobLoading ||
    updateQuestionsLoading ||
    isImageLoading ||
    deleteJobLoading;

  const jobPostLoading = isImageLoading || questionsLoading || createJobLoading || updateJobLoading;

  const { $, attrs, set, validate, getError, useConfig } = useForm({
    initial: INITIAL_FIELDS,
    validations: {
      imageUrl: 'presence',
      title: 'presence',
      location: 'presence',
      webUrl: [{ url: { protocol: true } }],
      description: [
        {
          presence: true,
          ...(MAX_DESCR_LENGTH > 0 ? { maxLength: { max: MAX_DESCR_LENGTH } } : {})
        }
      ],
      requirements: [
        {
          ...(MAX_REQ_LENGTH > 0 ? { maxLength: { max: MAX_REQ_LENGTH } } : {})
        }
      ],
      applicantAmount: ['presence', { numericality: { greaterThan: 0 } }],
      'questions.*.name': 'presence',
      city: 'presence',
      countryId: ['presence', 'numericality'],
      stateId: ['presence', 'numericality'],
      payCurrencyId: ['presence', 'numericality'],
      payPeriodId: ['presence', 'numericality'],
      payMin: [
        'presence',
        {
          numericality: {
            greaterThan: 0,
            numberOfDecimals: 2,
            onlyInteger: false,
            message: {
              notValid: 'Please, enter a valid number (e.g., 123, 123.99)'
            }
          }
        }
      ],
      payMax: [
        'presence',
        {
          numericality: {
            greaterThan: 0,
            numberOfDecimals: 2,
            onlyInteger: false,
            message: {
              notValid: 'Please, enter a valid number (e.g., 123, 123.99)'
            }
          }
        }
      ],
      endDate: [
        { date: { earliest: new Date(), messages: { tooEarly: 'Must not be in the past' } } }
      ],
      hireDate: [
        { date: { earliest: new Date(), messages: { tooEarly: 'Must not be in the past' } } }
      ],
      industry: { minLength: { min: 1, message: 'Please, add some industries' } },
      jobTypeId: {
        rules: [
          (_, { attrs: a }) => {
            if (!a.industry.length) return 'Please, select some industry first';
            return '';
          },
          'presence',
          'numericality'
        ],
        deps: ['industry']
      },
      interviewType: 'presence'
    }
  });
  // console.log('🚀 -> JobPosting -> attrs', attrs);

  const selectedPayPeriodOption = useMemo(
    () => getPayPeriodById(attrs.payPeriodId),
    [getPayPeriodById, attrs.payPeriodId]
  );

  const selectedCurrencyOption = useMemo(
    () => getCurrencyById(attrs.payCurrencyId),
    [getCurrencyById, attrs.payCurrencyId]
  );

  const selectedCountryOption = useMemo(
    () => getCountryById(attrs.countryId),
    [getCountryById, attrs.countryId]
  );

  const selectedStateOption = useMemo(
    () => getStateById(attrs.stateId),
    [getStateById, attrs.stateId]
  );

  const currentStates = states.filter(
    (stateObj) => stateObj?.country?.name === selectedCountryOption?.name
  );

  useConfig(() => {
    if (attrs.interviewType === INTERVIEW_TYPES.web) {
      return {
        validations: {
          location: ['presence', 'url']
        }
      };
    }
    if (attrs.interviewType === INTERVIEW_TYPES.phone) {
      return {
        validations: {
          location: ['presence', 'phone']
        }
      };
    }
    if (selectedCountryOption.code) {
      return {
        validations: {
          zip: ['presence', { zip: { countryCode: selectedCountryOption.code } }]
        }
      };
    }
    return {
      validations: {
        payMax: [
          'presence',
          {
            numericality: {
              greaterThan: Number(attrs.payMin) || 0,
              numberOfDecimals: 2,
              onlyInteger: false,
              message: {
                notValid: 'Please, enter a valid number (e.g., 123, 123.99)'
              }
            }
          }
        ]
      }
    };
  }, [attrs.interviewType, attrs.payMin, attrs.payMax, selectedCountryOption.code]);

  const {
    active,
    applicantAmount,
    city,
    draft,
    description,
    endDate,
    hireDate,
    imageUrl,
    industry,
    interviewType,
    jobTypeId,
    location,
    newImageFile,
    payMin,
    payMax,
    questions,
    requirements,
    title,
    webUrl,
    zip
  } = attrs;

  const jobTypesByIndustry = useMemo(() => {
    if (industry?.length) {
      const industryIds = map(industry, 'industry_id');
      const filteredByInd = jobTypes.filter((o) => industryIds.includes(o.industryId));
      return map(filteredByInd, (obj) => {
        const duplicates = filteredByInd.filter((o) => o.name === obj.name);
        if (duplicates.length > 1) {
          const { name: industryName } = find(industry, (o) => o.industry_id === obj.industryId);
          return { ...obj, name: industryName ? `${obj.name} (${industryName})` : obj.name };
        }
        return obj;
      });
    }
    return [];
  }, [industry, jobTypes]);

  const formattedHireDate = useMemo(
    () => (hireDate && isValidDate(hireDate) ? format(hireDate, FRONT_DATE_FORMAT) : ''),
    [hireDate]
  );
  const formattedEndDate = useMemo(
    () => (endDate && isValidDate(endDate) ? format(endDate, FRONT_DATE_FORMAT) : ''),
    [endDate]
  );

  useEffect(() => {
    if (idParam) fetchFullJob(idParam);
    fetchCountryState();
    getJobTypes();
  }, []);

  useEffect(() => {
    if (fullJob) {
      const jobToSettle = {};

      Object.entries(fullJob).forEach(([key, value]) => {
        jobToSettle[key] = value == null ? INITIAL_FIELDS[key] || value : value;
      });

      set({
        ...jobToSettle,
        hireDate: fullJob.hireDate ? serverDateToDateObj(fullJob.hireDate) : null,
        endDate: fullJob.endDate ? serverDateToDateObj(fullJob.endDate) : null
      });
    }
  }, [JSON.stringify(fullJob)]);

  useEffect(() => {
    const defaultQuestions = [
      { tempId: nanoid(), name: 'Are you at least 18 years old?' },
      { tempId: nanoid(), name: 'Do you meet the requirements for this position?' },
      {
        tempId: nanoid(),
        name: 'If offered the position, would you be able to start within 3 weeks?'
      }
    ];

    if (profile) {
      if (isNewJobPage) {
        const {
          address: profAddress,
          stateId: profStateId,
          countryId: profCountryId,
          city: profCity,
          zip: profZip
        } = profile;

        set({
          location: profAddress || '',
          stateId: profStateId || INIT_ADDRESS_FORM.stateId,
          countryId: profCountryId || INIT_ADDRESS_FORM.countryId,
          city: profCity || INIT_ADDRESS_FORM.city,
          zip: profZip || INIT_ADDRESS_FORM.zip,
          questions: defaultQuestions
        });
      }
    } else if (isNewJobPage) {
      set({ questions: defaultQuestions });
    }
  }, [JSON.stringify(profile)]);

  useEffect(() => {
    // reset selected state if another country has been selected
    if (
      selectedCountryOption.name &&
      selectedStateOption.name &&
      selectedStateOption?.country?.name !== selectedCountryOption.name
    ) {
      set({ stateId: '' });
    }
  }, [selectedCountryOption.name, selectedStateOption.name]);

  useEffect(() => {
    if (jobTypesByIndustry.length && jobTypeId) {
      // reset selected jobTypeId if there is no such option
      const industryIds = map(industry, 'industry_id');
      const jobTypeObject = find(jobTypesByIndustry, { id: jobTypeId });
      if (!jobTypeObject || (jobTypeObject && !industryIds.includes(jobTypeObject.industryId))) {
        set({ jobTypeId: '' });
      }
    }
  }, [industry, jobTypesByIndustry.length, jobTypeId]);

  const getCoppedImageFile = (jobId) => {
    const file = attrs.newImageFile;

    if (imageEditorRef.current && file) {
      const canvasScaled = imageEditorRef.current.getImageScaledToCanvas();
      const fileName = file?.name || 'jobimage';
      const ext = fileName.split('.').pop();
      const fileNameWithId = `${jobId}-${fileName}`;
      return new Promise((resolve, reject) => {
        canvasScaled.toBlob(async (blob) => {
          const blobToFile = new File([blob], fileNameWithId);
          resolve([blobToFile]);
        }, `image/${ext}`);
      });
    }

    return [];
  };

  const handlePostImage = async (jobId) => {
    setImageLoading(true);
    const [file] = await getCoppedImageFile(jobId);

    try {
      if (file) {
        await updateJobImg({ variables: { jobId: Number(jobId), file } });
        setImageChanged(false);
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      console.error(error);
      showNotification([
        {
          type: 'error',
          message: `Some error occurred during while updating job image. ${error.message}`
        }
      ]);
      return { success: false };
    } finally {
      setImageLoading(false);
    }
  };

  const handlePostQuestions = async (jobId, questionParam) => {
    const questionArr = questionParam.map(({ name, questions_id }) => ({ name, questions_id }));
    try {
      await updateQuestions({
        variables: { jobsId: Number(jobId), questions: questionArr }
      });
      return { success: true };
    } catch (error) {
      console.error(error);
      showNotification([
        { type: 'error', message: `Some error occurred while updating questions. ${error.message}` }
      ]);
      return { success: false };
    }
  };

  const handleCreateJob = async (jobData) => {
    try {
      const response = await createJob({ variables: jobData });
      refetchEmployerProfile(); // update restrictions
      return { success: true, id: response?.data?.addJob?.id };
    } catch (error) {
      console.error(error);
      showNotification([
        { type: 'error', message: `Some error occurred while creating job. ${error.message}` }
      ]);
      return { success: false };
    }
  };

  const handleUpdateJob = async (jobData) => {
    try {
      const response = await updateJob({ variables: jobData });
      refetchEmployerProfile(); // update restrictions
      track('Update Job Posting');
      return { success: true };

    } catch (error) {
      console.error(error);
      showNotification([
        { type: 'error', message: `Some error occurred while updating job. ${error.message}` }
      ]);
      return { success: false };
    }
  };

  const handleJobPost = (isDraft = false, isCopy = false) => {
    validate()
      .then(async (validAttrs) => {
        const isNewJob = isNewJobPage || isCopy;
        const payRange = `${payMin}-${payMax} ${selectedCurrencyOption?.name} ${selectedPayPeriodOption?.name}`;

        const jobDataParams = {
          applicantAmount: Number(validAttrs.applicantAmount),
          city: trimStr(validAttrs.city),
          countryId: Number(validAttrs.countryId),
          description: validAttrs.description,
          draft: isDraft,
          endDate: validAttrs.endDate ? format(validAttrs.endDate, SERVER_DATE_FORMAT) : null,
          hireDate: validAttrs.hireDate ? format(validAttrs.hireDate, SERVER_DATE_FORMAT) : null,
          industry: validAttrs.industry
            ? validAttrs.industry.map(({ name, industry_id: id }) => ({ name, id }))
            : [],
          interviewType: validAttrs.interviewType,
          jobTypeId: Number(validAttrs.jobTypeId),
          location: trimStr(validAttrs.location),
          payRange, // ! currently required on server
          payMin: Number(validAttrs.payMin),
          payMax: Number(validAttrs.payMax),
          payPeriodId: Number(validAttrs.payPeriodId),
          payCurrencyId: Number(validAttrs.payCurrencyId),
          requirements: validAttrs.requirements,
          skills: validAttrs.skills.map(dropTypeName),
          stateId: Number(validAttrs.stateId),
          title: trimStr(validAttrs.title),
          webUrl: validAttrs.webUrl,
          zip: trimStr(validAttrs.zip)
        };

        if (isNewJob) {
          const { success, id } = await handleCreateJob(jobDataParams);
          if (id) {
            await Promise.all([handlePostQuestions(id, validAttrs.questions), handlePostImage(id)]);
            // leave job creation page despite questions and image result
            track('Create New Job');
            navigate(ROUTES.employer.dashboard);
          }
        } else {
          const results = await Promise.all([
            handleUpdateJob({ ...jobDataParams, id: Number(idParam) }),
            handlePostQuestions(idParam, validAttrs.questions),
            ...(isImageChanged ? [handlePostImage(idParam)] : [])
          ]);
          if (!results.find((o) => !o.success)) refetchFullJob();
        }
      })
      .catch((errors) => {
        const fieldsWithErr = Object.keys(errors).filter((field) => errors[field]);
        if (fieldsWithErr[0]) {
          const errorElement = document.getElementById(`${fieldsWithErr[0]}-input-helper`);
          if (errorElement) errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
  };

  const handleJobCopy = async () => {
    handleJobPost(draft, true);
  };

  const confirmDelete = async () => {
    await deleteJob({ variables: { id: Number(idParam) } });
    await refetchEmployerProfile(); // update restrictions
    setIsConfirmModalOpen(false);
    track('Delete Job Posting');
    navigate(ROUTES.employer.dashboard);
  };

  const handleJobDelete = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleFieldChange = useCallback((e, { name }) => {
    set(name, e.target.value);
  }, []);

  const handleFormChange = useCallback((nextForm, isIndustry) => {
    const formatted = isIndustry
      ? { industry: nextForm.industry.map((o) => ({ ...o, industry_id: o.id })) }
      : nextForm;
    set({ ...formatted });
  }, []);

  const handleInterviewTypeChange = (e, { name }) => {
    set({
      [name]: e.target.value,
      location: '' // reset location on interview type change
    });
  };

  const handleInterviewLocationChange = (e, { name }) => {
    const { value } = e.target;
    let nextValue = value;
    if (interviewType === INTERVIEW_TYPES.web) nextValue = value.toLowerCase();
    if (interviewType === INTERVIEW_TYPES.phone) nextValue = unformatPhone(value);
    set(name, nextValue);
  };

  const addQuestion = (questionObj) => {
    set('questions', [...questions, questionObj]);
  };

  const deleteQuestion = (id, index) => {
    set(
      'questions',
      questions.filter((o) => o.questions_id !== id)
    );
  };

  function handleDateChange(field) {
    return (date, inputValue) => {
      set(field, date);
    };
  }

  const onImageUpload = (result, file) => {
    setImageChanged(true);
    set({ imageUrl: result, newImageFile: file });
  };

  const selectProps = {
    inputVariant: 'outlined',
    InputComponentProps: {
      labelClassName: 'label',
      inputClassName: 'select',
      htmlInputClassName: 'select__input'
    },
    native: false,
    required: true,
    withHelperText: true,
    withEmptyOption: true
  };

  const renderHeader = () => (
    <div className="header">
      <div className="title">New Job Posting</div>
      <Box mt="-8px" display="flex" alignItems="center" flexWrap="wrap">
        {isEditJobPage && draft && (
          <Button
            variant="outlined-primary"
            className="jobAction danger"
            endIcon={deleteJobLoading ? <Spinner size={12} /> : null}
            disabled={jobProcessing}
            onClick={handleJobDelete}
            testID="delete-job-button"
          >
            Delete
          </Button>
        )}
        {(draft || isNewJobPage) && (
          <Button
            variant="outlined-primary"
            className="jobAction"
            endIcon={jobPostLoading ? <Spinner size={15} /> : null}
            disabled={!canSaveDraft || jobProcessing}
            onClick={() => handleJobPost(true)}
            testID="save-draft-button"
          >
            Save Draft
          </Button>
        )}
        {!isNewJobPage && active && (
          <Button
            variant="outlined-primary"
            className="jobAction"
            endIcon={jobPostLoading ? <Spinner size={15} /> : null}
            disabled={!canCopy || jobProcessing}
            onClick={() => handleJobCopy()}
            testID="copy-job-button"
          >
            {`Copy ${draft ? 'Draft' : 'Job'}`}
          </Button>
        )}
        {!isNewJobPage && (
          <ToggleJobActiveState jobId={Number(idParam)} jobTitle={title}>
            {({ openJob, closeJob, loading: activationLoading }) => (
              <Button
                variant="outlined-primary"
                className="jobAction"
                endIcon={activationLoading ? <Spinner size={15} /> : null}
                disabled={(!active && !canActivate) || activationLoading}
                onClick={() =>
                  active
                    ? closeJob({ onSuccess: () => {
                        navigate(ROUTES.employer.dashboard)
                        track('Close Job Posting'); 
                      }
                    })
                    : openJob({ onSuccess: () => {
                      navigate(ROUTES.employer.dashboard)
                      track('Re-open Closed Job Posting'); 
                      }
                    })
                }
                testID="activate-job-button"
              >
                {active ? 'Close Job Posting' : 'Re-open Job'}
              </Button>
            )}
          </ToggleJobActiveState>
        )}
        <Button
          variant="filled-primary"
          className="jobAction"
          endIcon={jobPostLoading ? <Spinner size={15} /> : null}
          disabled={!canPost || jobProcessing}
          onClick={() => handleJobPost()}
          testID="post-job-button"
        >
          Post Job
        </Button>
      </Box>
    </div>
  );

  const renderImage = () => {
    const imgError = getError('imageUrl');

    return (
      <div className="imageContainer">
        {isEdit ? (
          <div className={clsx('label', imgError && 'error')}>
            Job Post Image
            <Box component="span" color="error.main" aria-hidden>
              *
            </Box>
          </div>
        ) : (
          <div className="label">Job Post Image</div>
        )}
        <EditableJobImage
          isEditMode={isEdit}
          avatarEditorRef={imageEditorRef}
          imageUrl={newImageFile || imageUrl}
          onUpload={onImageUpload}
          editorClassName={clsx('avatarEditor', imgError && 'error')}
          renderError={() =>
            imgError && (
              <FormHelperText id="imageUrl-input-helper" error>
                {imgError}
              </FormHelperText>
            )
          }
          renderUploadButton={() => (
            <Button
              component="span"
              variant="filled-primary"
              disabled={jobProcessing}
              className="mainAction"
              testID="photo-edit-button"
            >
              Upload New
            </Button>
          )}
        />
      </div>
    );
  };

  const renderDisclaimer = () => (
    <p className="disclaimerText">
      By pressing Post, you agree that this job will be posted and applications will be processed in
      accordance with WorkTorch's Privacy Policy and Terms of Service. You consent to WorkTorch
      informing a user that you have opened, viewed or made a decision regarding the user's
      application.
    </p>
  );

  const renderQuestions = () => (
    <div className="questionsContainer">
      <div className="questionsTitle">Questions:</div>
      {questionsLoading && (
        <Box py="10px" display="flex" justifyContent="center">
          <Spinner size={24} />
        </Box>
      )}
      <EditableJobQuestions
        isEditMode={isEdit}
        questions={questions}
        bindInput={(inputName) => $(inputName, (e, { name }) => set(name, e.target.value))}
        onRemove={deleteQuestion}
        onAdd={addQuestion}
      />
    </div>
  );

  const renderAddressFields = () => (
    <div className="formItem row">
      <EditableField isEditMode={isEdit} label="City" value={city} containerClassName="col25">
        <Input
          {...$('city', handleFieldChange)}
          variant="textfield"
          id="city-input"
          required
          label="City"
          labelClassName="label"
          className="rowInput col25"
          withHelperText
          analyticParams={{
            key: 'Job city focused',
            trigger: 'focus'
          }}
          testID="city-input"
        />
      </EditableField>

      <EditableField
        isEditMode={isEdit}
        label="State"
        value={selectedStateOption.name || 'N/D'}
        containerClassName="col25"
      >
        <Select
          {...$('stateId', handleFieldChange)}
          {...selectProps}
          id="stateId-select"
          required
          label="State"
          className="rowInput col25"
          analyticParams={{
            key: 'Job state focused',
            trigger: 'focus'
          }}
          options={map(currentStates, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`state-option-${i}`)
          }))}
          testID="stateId-input"
        />
      </EditableField>

      <EditableField isEditMode={isEdit} label="Zip Code" value={zip} containerClassName="col25">
        <Input
          {...$('zip', handleFieldChange)}
          variant="textfield"
          id="zip-input"
          required
          label="Zip Code"
          labelClassName="label"
          className="rowInput col25"
          withHelperText
          analyticParams={{
            key: 'Job zip focused',
            trigger: 'focus'
          }}
          testID="zip-input"
        />
      </EditableField>

      <EditableField
        isEditMode={isEdit}
        label="Country"
        value={selectedCountryOption.name || 'N/D'}
        containerClassName="col25"
      >
        <Select
          {...$('countryId', handleFieldChange)}
          {...selectProps}
          id="countryId-select"
          label="Country"
          className="rowInput col25"
          analyticParams={{
            key: 'Job country focused',
            trigger: 'focus'
          }}
          options={map(countries, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`country-option-${i}`)
          }))}
          testID="countryId-input"
        />
      </EditableField>
    </div>
  );

  const renderPayRangeFields = () => (
    <div className="formItem row">
      <EditableField
        isEditMode={isEdit}
        label="Currency"
        value={selectedCurrencyOption.name || 'N/D'}
        containerClassName="mobOrder-1"
      >
        <Select
          {...$('payCurrencyId', handleFieldChange)}
          {...selectProps}
          id="payCurrencyId-select"
          label="Currency"
          className="currencyInput rowInput mobOrder-1"
          analyticParams={{
            key: 'Job currency focused',
            trigger: 'focus'
          }}
          options={map(currencies, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`payCurrencyId-option-${i}`)
          }))}
          testID="payCurrencyId-input"
        />
      </EditableField>

      <EditableField
        isEditMode={isEdit}
        label="Min Pay"
        value={payMin}
        containerClassName="mobOrder-3"
      >
        <Input
          {...$('payMin', handleFieldChange)}
          variant="textfield"
          id="payMin-input"
          required
          label="Min Pay"
          labelClassName="label"
          className="rowInput mobOrder-3"
          withHelperText
          analyticParams={{
            key: 'Job min pay focused',
            trigger: 'focus'
          }}
          testID="payMin-input"
        />
      </EditableField>

      <EditableField
        isEditMode={isEdit}
        label="Max Pay"
        value={payMax}
        containerClassName="mobOrder-4"
      >
        <Input
          {...$('payMax', handleFieldChange)}
          variant="textfield"
          id="payMax-input"
          required
          label="Max Pay"
          labelClassName="label"
          className="rowInput mobOrder-4"
          withHelperText
          analyticParams={{
            key: 'Job max pay focused',
            trigger: 'focus'
          }}
          testID="payMax-input"
        />
      </EditableField>

      <EditableField
        isEditMode={isEdit}
        label="Frequency"
        value={selectedPayPeriodOption.name || 'N/D'}
        containerClassName="mobOrder-2"
      >
        <Select
          {...$('payPeriodId', handleFieldChange)}
          {...selectProps}
          id="payPeriodId-select"
          label="Frequency"
          className="rowInput mobOrder-2"
          analyticParams={{
            key: 'Job pay frequency focused',
            trigger: 'focus'
          }}
          options={map(payPeriods, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`payPeriodId-option-${i}`)
          }))}
          testID="payPeriodId-input"
        />
      </EditableField>
    </div>
  );

  const renderSkillsSection = () => (
    <div className="formItem">
      <div className="label">Skills</div>
      <EditableJobSkills isEditMode={isEdit} form={attrs} onFormChange={handleFormChange} />
    </div>
  );

  const renderIndustrySection = () => {
    const industryError = getError('industry');
    return (
      <div className="formItem">
        <div className="label">Industries</div>
        <EditableJobIndustries
          isEditMode={isEdit}
          form={attrs}
          onFormChange={(nextForm) => handleFormChange(nextForm, true)}
        />
        {industryError && <FormHelperText error>{industryError}</FormHelperText>}
      </div>
    );
  };

  const renderInterviewLocationInput = () => {
    const commonProps = {
      variant: 'textfield',
      id: 'location-input',
      required: true,
      labelClassName: 'label',
      withHelperText: true,
      analyticParams: {
        key: 'Job interview location focused',
        trigger: 'focus'
      },
      testID: 'location-input'
    };

    if (interviewType === INTERVIEW_TYPES.phone) {
      return (
        <PhoneInput
          {...$('location', handleInterviewLocationChange)}
          {...commonProps}
          label="Interview Phone"
          placeholder="1-123-456-7890"
        />
      );
    }
    if (interviewType === INTERVIEW_TYPES.web) {
      return (
        <Input
          {...$('location', handleInterviewLocationChange)}
          {...commonProps}
          label="Interview Link"
          type="url"
          placeholder="https://www.example.com"
        />
      );
    }
    return (
      <Input
        {...$('location', handleInterviewLocationChange)}
        {...commonProps}
        label="Interview Location"
      />
    );
  };

  const renderFields = () => (
    <>
      <EditableField isEditMode={isEdit} label="Job Title" value={title}>
        <div className="formItem">
          <Input
            {...$('title', handleFieldChange)}
            variant="textfield"
            id="title-input"
            required
            label="Job Title"
            labelClassName="label"
            withHelperText
            analyticParams={{
              key: 'Job title focused',
              trigger: 'focus'
            }}
            testID="title-input"
          />
        </div>
      </EditableField>

      {renderSkillsSection()}
      {renderIndustrySection()}

      {isEdit && (
        <div className="formItem">
          <Select
            {...$('jobTypeId', handleFieldChange)}
            {...selectProps}
            id="jobTypeId-select"
            required
            label="Choose a job most similar to your job"
            disabled={jobTypesLoading || !jobTypesByIndustry.length || !industry.length}
            analyticParams={{
              key: 'Job type focused',
              trigger: 'focus'
            }}
            options={map(jobTypesByIndustry, (o, i) => ({
              value: o.id,
              label: o.name,
              ...qaAttr(`jobType-option-${i}`)
            }))}
            withEmptyOption={false}
            testID="jobType-input"
          />
        </div>
      )}

      <EditableField
        isEditMode={isEdit}
        label="Interview Type"
        value={find(INTERVIEW_TYPES_OPTIONS, { value: interviewType })?.label || 'N/A'}
      >
        <div className="formItem">
          <Select
            {...$('interviewType', handleInterviewTypeChange)}
            {...selectProps}
            id="interviewType-select"
            required
            label="Interview Type"
            disabled={!INTERVIEW_TYPES_OPTIONS.length}
            analyticParams={{
              key: 'Job interview type focused',
              trigger: 'focus'
            }}
            options={map(INTERVIEW_TYPES_OPTIONS, (o, i) => ({
              value: o.value,
              label: o.label,
              ...qaAttr(`interviewType-option-${i}`)
            }))}
            withEmptyOption={false}
            testID="interviewType-input"
          />
        </div>
      </EditableField>

      <EditableField
        isEditMode={isEdit}
        label={(() => {
          if (interviewType === INTERVIEW_TYPES.web) return 'Interview Link';
          if (interviewType === INTERVIEW_TYPES.phone) return 'Interview Phone';
          return 'Interview Location';
        })()}
        value={location}
      >
        <div className="formItem">{renderInterviewLocationInput()}</div>
      </EditableField>

      {renderAddressFields()}
      {renderPayRangeFields()}

      <EditableField isEditMode={isEdit} label="Job Description" value={description}>
        <div className="formItem">
          <Input
            {...$('description', handleFieldChange)}
            variant="outlined"
            id="description-input"
            multiline
            maxRows={5}
            label={
              MAX_DESCR_LENGTH > 0 ? (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>
                    Job Description
                    <Box component="span" color="error.main" aria-hidden>
                      *
                    </Box>
                  </span>
                  <span className="labelHint">{`${description.length}/${MAX_DESCR_LENGTH}`}</span>
                </Box>
              ) : (
                'Job Description*'
              )
            }
            inputProps={{
              required: true,
              ...(MAX_DESCR_LENGTH > 0 && { maxLength: MAX_DESCR_LENGTH })
            }}
            labelClassName="label"
            inputClassName="textarea"
            htmlInputClassName="textareaInput"
            withHelperText
            analyticParams={{
              key: 'Job description focused',
              trigger: 'focus'
            }}
            testID="description-input"
          />
        </div>
      </EditableField>

      <EditableField isEditMode={isEdit} label="Job Requirements" value={requirements}>
        <div className="formItem">
          <Input
            {...$('requirements', handleFieldChange)}
            variant="outlined"
            id="requirements-input"
            multiline
            maxRows={5}
            label={
              MAX_REQ_LENGTH > 0 ? (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>Job Requirements (separate each requirement by a comma)</span>
                  <span className="labelHint">{`${requirements.length}/${MAX_REQ_LENGTH}`}</span>
                </Box>
              ) : (
                'Job requirements'
              )
            }
            inputProps={{
              ...(MAX_REQ_LENGTH > 0 && { maxLength: MAX_REQ_LENGTH })
            }}
            labelClassName="label"
            inputClassName="textarea"
            htmlInputClassName="textareaInput"
            withHelperText
            analyticParams={{
              key: 'Job requirements focused',
              trigger: 'focus'
            }}
            testID="requirements-input"
          />
        </div>
      </EditableField>

      <div className="formItem row">
        <EditableField isEditMode={isEdit} label="End Posting Date" value={formattedEndDate}>
          <DatePicker
            {...$('endDate')}
            label="End Posting Date"
            onChange={handleDateChange('endDate')}
            disablePast
            InputComponentProps={{
              id: 'endDate-input',
              className: 'rowInput',
              labelClassName: 'label',
              analyticParams: {
                key: 'Job end posting date focused',
                trigger: 'focus'
              },
              testID: 'endDate-input'
            }}
          />
        </EditableField>

        <EditableField isEditMode={isEdit} label="Date Needed" value={formattedHireDate}>
          <DatePicker
            {...$('hireDate')}
            label="Date Needed"
            onChange={handleDateChange('hireDate')}
            disablePast
            InputComponentProps={{
              id: 'hireDate-input',
              className: 'rowInput',
              labelClassName: 'label',
              analyticParams: {
                key: 'Job hire date focused',
                trigger: 'focus'
              },
              testID: 'hireDate-input'
            }}
          />
        </EditableField>

        <EditableField
          isEditMode={isEdit}
          label="Maximum Applicant Amount"
          value={applicantAmount}
          containerClassName="mobFullWidth"
        >
          <Input
            {...$('applicantAmount', handleFieldChange)}
            type="number"
            variant="textfield"
            id="applicantAmount-input"
            required
            label="Maximum Applicant Amount"
            labelClassName="label"
            className="rowInput mobFullWidth"
            withHelperText
            inputProps={{ min: 1, step: 1 }}
            analyticParams={{
              key: 'Job max applicant amount focused',
              trigger: 'focus'
            }}
            testID="applicantAmount-input"
          />
        </EditableField>
      </div>

      <EditableField
        isEditMode={isEdit}
        label="ATS Link (Will send to applicant upon approval of applicant)"
        value={webUrl}
      >
        <div className="formItem">
          <Input
            {...$('webUrl', handleFieldChange)}
            variant="textfield"
            id="webUrl-input"
            placeholder="http://example.com"
            label="ATS Link (Will send to applicant upon approval of applicant)"
            labelClassName="label"
            withHelperText
            analyticParams={{
              key: 'Job web url focused',
              trigger: 'focus'
            }}
            testID="webUrl-input"
          />
        </div>
      </EditableField>
    </>
  );

  const renderDesktopLayout = () => (
    <>
      {renderHeader()}
      <div className="contentWrapper">
        <Box display="flex">
          <div className="layoutColumn">
            {renderImage()}
            {renderQuestions()}
          </div>
          <div className="layoutColumn">
            {renderFields()}
            {renderDisclaimer()}
          </div>
        </Box>
      </div>
    </>
  );

  const renderMobileLayout = () => (
    <>
      {renderHeader()}
      <div className="contentWrapper">
        {renderImage()}
        {renderFields()}
        {renderQuestions()}
        {renderDisclaimer()}
      </div>
    </>
  );

  return (
    <StyledRoot className="container">
      {jobLoading && (
        <div className="loaderOverlay">
          <Spinner size={60} />
        </div>
      )}
      {isDesktop ? renderDesktopLayout() : renderMobileLayout()}
      <ConfirmationDialog
        isOpen={isConfirmModalOpen}
        title="Are you sure you want to delete job?"
        onConfirm={confirmDelete}
        onCancel={closeConfirmModal}
        onClose={closeConfirmModal}
      />
    </StyledRoot>
  );
}

function EditableField({
  // eslint-disable-next-line
  children,
  isEditMode,
  value,
  label,
  containerClassName,
  valueClassName,
  labelClassName
}) {
  return isEditMode ? (
    children
  ) : (
    <div className={clsx('rowInput', containerClassName)}>
      <div className={clsx('label', labelClassName)}>{label}</div>
      <div className={clsx('value', valueClassName)}>{value}</div>
    </div>
  );
}

export default memo(JobPosting);
