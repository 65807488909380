import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MuiIconButton, styled, Tooltip } from 'components';
import { track } from 'utils/segmentAnalytics';
import { qaAttr } from 'utils';

const PREFIX = 'StyledIconButton';
const classes = {
  tooltip: `${PREFIX}-tooltip`,
  tooltipTop: `${PREFIX}-tooltipTop`,
  tooltipBottom: `${PREFIX}-tooltipBottom`
};

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{
      popper: className,
      tooltip: classes.tooltip,
      tooltipPlacementTop: classes.tooltipTop,
      tooltipPlacementBottom: classes.tooltipBottom
    }}
  />
))(({ theme }) => ({
  [`& .${classes.tooltip}`]: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent'
  },
  [`& .${classes.tooltipTop}`]: {
    marginBottom: '8px !important'
  },
  [`& .${classes.tooltipBottom}`]: {
    marginTop: '0 !important'
  }
}));

function IconButton(props) {
  const {
    analyticParams,
    component,
    className,
    height,
    isRouterLink,
    onClick,
    toolTipProps,
    testID,
    variant,
    width,
    withTooltip,
    ...iconButtonProps
  } = props;
  const baseTooltipProps = {
    placement: 'top',
    disableTouchListener: true,
    PopperProps: { disablePortal: true },
    ...toolTipProps
  };

  const handleClick = (e) => {
    if (onClick) onClick(e);
  };

  return withTooltip && !iconButtonProps.disabled ? (
    <StyledTooltip {...baseTooltipProps}>
      <MuiIconButton
        component={isRouterLink ? Link : component}
        className={className}
        onClick={handleClick}
        {...(testID && qaAttr(testID))}
        {...iconButtonProps}
      />
    </StyledTooltip>
  ) : (
    <MuiIconButton
      component={isRouterLink ? Link : component}
      className={className}
      onClick={handleClick}
      {...(testID && qaAttr(testID))}
      {...iconButtonProps}
    />
  );
}

IconButton.propTypes = {
  analyticParams: PropTypes.shape({
    key: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any)
  }),
  component: PropTypes.string,
  className: PropTypes.string,
  isRouterLink: PropTypes.bool,
  onClick: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  toolTipProps: PropTypes.objectOf(PropTypes.any),
  testID: PropTypes.string,
  variant: PropTypes.oneOf(['', 'outlined', 'filled-primary']),
  withTooltip: PropTypes.bool
};

IconButton.defaultProps = {
  analyticParams: { key: '' },
  component: 'button',
  className: '',
  isRouterLink: false,
  onClick: () => {},
  height: 45,
  width: 45,
  toolTipProps: {},
  testID: '',
  variant: '',
  withTooltip: false
};

const StyledIconButton = styled(IconButton)(({ theme, variant, height, width, size }) => ({
  borderRadius: 50,
  height,
  width,
  '&.Mui-disabled': {
    color: theme.palette.moreColors.disabled,
    backgroundColor: 'transparent'
  },
  ...(variant === 'outlined' && {
    padding: 9,
    border: `1px solid ${theme.palette.primary.light}`,
    backgroundColor: 'transparent',
    '&:hover': {
      color: '#FFF',
      borderColor: theme.palette.moreColors.mainPrimaryLucid,
      backgroundColor: theme.palette.primary.light
    }
  }),
  ...(variant === 'filled-primary' && {
    padding: 9,
    color: theme.palette.primary.text,
    backgroundColor: theme.palette.primary.light,
    '&.Mui-disabled': {
      color: theme.palette.moreColors.disabled,
      backgroundColor: theme.palette.moreColors.mainPrimaryLucid
    },
    '&:hover': {
      color: theme.palette.primary.text,
      backgroundColor: theme.palette.primary.light_2
    }
  })
}));

export default StyledIconButton;
