import React from 'react';
import PT from 'prop-types';
import { MuiButtonBase } from 'components';
import { track } from 'utils/segmentAnalytics';

function ButtonBase({ analyticParams, onClick, ...rest }) {
  const handleClick = (e) => {
    if (onClick) onClick(e);
  };

  return <MuiButtonBase onClick={handleClick} {...rest} />;
}

ButtonBase.propTypes = {
  analyticParams: PT.shape({
    key: PT.string,
    params: PT.objectOf(PT.any)
  }),
  onClick: PT.func
};

ButtonBase.defaultProps = {
  analyticParams: { key: '' },
  onClick: () => {}
};

export default ButtonBase;
